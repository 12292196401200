import { environment } from './../../../environments/environment.development';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-primary-nav',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgOptimizedImage],
  styleUrl: './primary-nav.component.scss',
  template: `
    <nav class="navbar navbar-expand-lg navbar-light">
      <a class="navbar-brand" href="{{ environment.catalogUrl }}">
        <img priority ngSrc="/img/logo.png" width="250" height="70" alt="QuizTime logo" />
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#primary-nav" aria-controls="primary-nav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="primary-nav">
        <ul class="navbar-nav mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" href="{{ environment.catalogUrl }}/my-quizzes">My Quizzes</a>
          </li>
        </ul>
      </div>
    </nav>
  `
})
export class PrimaryNavComponent {
  public environment = environment;
}
