import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Token, TokenStorageService } from '../../services/token-storage/token-storage.service';
import { AsyncPipe, JsonPipe } from '@angular/common';
import { LtiUser } from '../../models/lti-user.interface';
import { QuiztimeApiService } from '../../services/quiztime-api.service';
import { AuthStore } from '../../store/auth.store';

@Component({
  selector: 'app-developer-debugger',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    JsonPipe,
  ],
  providers: [
    AuthStore
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './developer-debugger.component.html',
  styleUrl: './developer-debugger.component.scss'
})
export class DeveloperDebuggerComponent {
  private formBuilder = inject(FormBuilder);
  private tokenStorage: TokenStorageService = inject(TokenStorageService);
  readonly authStore = inject(AuthStore);
  _user = this.authStore.user();

  userInfo!: LtiUser | null;

  accessTokenForm = this.formBuilder.group({
    access_token: ['', Validators.required],
    expires_in: [3599, Validators.required],
  });

  constructor() {
    if (environment.production) {
      return;
    }

  }

  get token() {
    return this.tokenStorage.getToken();
  }

  submitAccessToken() {
    this.tokenStorage.setToken(this.accessTokenForm.value as Token);
    this.accessTokenForm.reset();
    this.authStore.updateUserRx();
  }
}
