import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ReactiveFormsModule, FormBuilder, Validators} from '@angular/forms';
import { commaOrWhitespaceSeparatedValidator } from '../../../../validators/comma-or-linebreak-separated.validator';
import { NgIf } from '@angular/common';
import { QuizErrorComponent } from '../../../../shared-components/quiz-error.component';
import { LoadingButtonComponent } from "../../../../shared-components/loading-button/loading-button.component";

@Component({
  selector: 'app-add-quiz-learners-input',
  styleUrls: ['../add-quiz-learners/add-quiz-learners.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, NgIf, QuizErrorComponent, LoadingButtonComponent],
  template: `
    <div [formGroup]="learnersInput">
    @if(error) {
      <div class="row">
        <div className="col-md-12">
        <app-quiz-error [error]="error"></app-quiz-error>
        </div>
      </div>
    }
      <p class="fw-bold mt-3">Add Learners: <p>
      <div class="input-group">
        <label for="name" class="form-label visually-hidden">* Email:</label>
        <input
          placeholder="Enter email addresses separated by commas or whitespace"
          formControlName="emailList"
          data-testid="add-quiz-learner__email-input"
          type="text"
          class="form-control"  />
        <app-loading-button
          [type]="'submit'"
          [loading]="isSubmitting"
          [disabled]="isSubmitting"
          [testId]="'add-quiz-learner__submit'"
          (click)="handleSubmit()"
          class="ms-2"
        >Send Invite</app-loading-button>
      </div>
      @if (emailList?.errors?.commaOrWhitespaceSeparatedErrors) {
        <p class="mt-1 p-0 text-danger">The following emails are not valid email addresses.</p>
        <ul class="list-unstyled text-danger" data-testid="add-quiz-learner__error-list">
          @for (email of emailList?.errors?.commaOrWhitespaceSeparatedErrors; track $index;) {
            <li>{{ email }}</li>
          }
        </ul>
      }
    </div>
  `
})

  export class AddQuizLearnersComponent {
    @Input() error!: string;
    @Input() isSubmitting!: boolean;
    @Output() formSubmitted = new EventEmitter<any>();

    private formBuilder = inject(FormBuilder);

    learnersInput = this.formBuilder.group({
      emailList: ['',[Validators.required, commaOrWhitespaceSeparatedValidator(Validators.email)]],
    });

  handleSubmit() {
    this.formSubmitted.emit({form: this.learnersInput.value});
  }

  get emailList() {
    return this.learnersInput.get('emailList');
  }

  get emailListErrors() {
    return this.emailList?.errors?.commaOrWhitespaceSeparatedErrors;
  }
}
