
import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, inject, Input, Output, TemplateRef } from '@angular/core';
import { CommonModule, JsonPipe } from '@angular/common';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTrashCan, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import { ConfirmationModalComponent } from "../../../../../shared-components/confirmation-modal/confirmation-modal.component";
import { Enrollment } from '../../../../../models/enrollment.interface';
import { QuizAdministratorView } from '../../../../../models/views/quiz-administrator-view.interface';

@Component({
  selector: 'app-administrator-actions',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, FontAwesomeModule, ConfirmationModalComponent],
  styleUrl: './administrator-actions.component.scss',
  template: `
    @if (!isOwner && !isPending) {
      <app-confirmation-modal
        (isAffirmative)="removeAdministrator()"
        [triggerButtonColor]="'transparent'"
      >
        <modal-header>Remove administrator access?</modal-header>
        <modal-body class="d-flex">
          <fa-icon class="text-warning fs-2" [icon]="triangleExclamation"></fa-icon>
          <span class="d-flex align-items-center ps-3">Are you sure you want to remove {{ enrollment.firstName }} {{ enrollment.lastName }} as an administrator?</span>
        </modal-body>
        <modal-affirmative>Remove access</modal-affirmative>
        <modal-negative>Cancel</modal-negative>
        <modal-trigger-inner>
          <fa-icon class="text-danger" [icon]="trashIcon"></fa-icon>
        </modal-trigger-inner>
      </app-confirmation-modal>
    }
    @else {
      @if (isPending && !isOwner) {
          <button (click)="removeAdministrator()" class="btn" type="button">
            <fa-icon class="text-danger" [icon]="trashIcon"></fa-icon>
            <span class="visually-hidden">Remove administrator</span>
          </button>
      }
    }
  `
})
export class AdministratorActionsComponent {
  @Input() enrollment!: QuizAdministratorView;
  @Input() isOwner!: boolean;
  @Input() isPending: boolean = false;
  @Output() administratorRemoved = new EventEmitter<string>();

  trashIcon = faTrashCan;
  triangleExclamation = faTriangleExclamation;

  removeAdministrator() {
    this.administratorRemoved.emit(this.enrollment.email);
  }
}
