import { Injectable } from '@angular/core';

export interface Token {
  access_token: string;
  expires_in: number;
}

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor() { }

  setToken(token: Token): void {
    if (token) {
      localStorage.setItem('token', JSON.stringify(token));
    }
  }

  getToken(): Token | null {
    const token = localStorage.getItem('token');
    return token ? JSON.parse(token) : null;
  }

  clear(): void {
    localStorage.removeItem('token');
  }
}
