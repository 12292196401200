import { inject } from '@angular/core';
import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../services/token-storage/token-storage.service';

export function QuizTimeApiInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const tokenStorage: TokenStorageService = inject(TokenStorageService);
  const token = tokenStorage.getToken();
  const isQuizTimeApiRequest = req.headers.has('X-QuizTimeApi');

  if (token && isQuizTimeApiRequest) {
    const cloned = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token.access_token}`
      },
      headers: req.headers.delete('X-QuizTimeApi')
    });
    return next(cloned);
  }

  return next(req);
}
