import { Injectable, computed, inject, signal } from '@angular/core';
import { QuiztimeApiService } from '../../services/quiztime-api.service';
import { Observable, tap, map } from 'rxjs';

export interface Announcements {
  start_of_quiz_announcement?: string;
  end_of_quiz_announcement?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AnnouncementsStateService {
  private api = inject(QuiztimeApiService);

  // State signals
  private loadingAnnouncementsSignal = signal(false);
  private announcementsSignal = signal<Announcements>({});
  private errorSignal = signal<string>('');
  private workingWithApiSignal = signal(false);

  // Computed values
  readonly loadingAnnouncements = computed(() => this.loadingAnnouncementsSignal());
  readonly announcements = computed<Announcements>(() => this.announcementsSignal());
  readonly error = computed(() => this.errorSignal());
  readonly workingWithApi = computed(() => this.workingWithApiSignal());

  initiateAnnouncementsState(quiz_guid: string): Observable<Announcements> {
    this.loadingAnnouncementsSignal.set(true);
    this.errorSignal.set('');

    return this.api.getAnnouncements(quiz_guid).pipe(
      tap({
        next: (announcements) => {
          this.announcementsSignal.set(announcements);
          this.loadingAnnouncementsSignal.set(false);
        },
        error: (error) => {
          this.errorSignal.set('Failed to load announcements');
          this.loadingAnnouncementsSignal.set(false);
          console.error('Error loading announcements:', error);
        }
      })
    );
  }

  resetState(): void {
    this.loadingAnnouncementsSignal.set(false);
    this.announcementsSignal.set({});
    this.errorSignal.set('');
    this.workingWithApiSignal.set(false);
  }

  updateAnnouncements(quiz_guid: string, announcements: Announcements): Observable<string> {
    this.workingWithApiSignal.set(true);
    this.errorSignal.set('');

    return this.api.createOrUpdateAnnouncements(
      quiz_guid,
      announcements.start_of_quiz_announcement || '',
      announcements.end_of_quiz_announcement || ''
    ).pipe(
      tap({
        next: () => {
          this.announcementsSignal.set(announcements);
          this.workingWithApiSignal.set(false);
        },
        error: (error) => {
          this.errorSignal.set('Failed to update announcements');
          this.workingWithApiSignal.set(false);
          console.error('Error updating announcements:', error);
        }
      })
    );
  }
}
