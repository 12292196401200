import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-form-error-display',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div *ngIf="hasErrors" class="alert alert-danger mt-3">
      <h5>Please fix the following errors:</h5>
      <ul>
        @for (error of errorsList; track $index) {
          <li>{{ error }}</li>
        }
      </ul>
      <small>Note that this is a generic error component - we need to determine a pattern for dealing with form errors, guided by desired UI. There was a brief discussion regarding it but we need to work with Kristi to build best experience.</small>
    </div>
  `
})
export class FormErrorDisplayComponent {
  @Input() form!: AbstractControl;

  get hasErrors(): boolean {
    return this.form.invalid && (this.form.dirty || this.form.touched);
  }

  get errorsList(): string[] {
    const errors: string[] = [];
    this.collectErrors(this.form, '', errors);
    return errors;
  }

  private collectErrors(control: AbstractControl, path: string, errors: string[]): void {
    if (control.errors) {
      Object.keys(control.errors).forEach(key => {
        const errorMsg = this.getErrorMessage(key, control.errors?.[key], path);
        errors.push(errorMsg);
      });
    }

    if (control instanceof FormGroup) {
      Object.keys(control.controls).forEach(key => {
        const childControl = control.get(key);
        if (childControl) {
          const childPath = path ? `${path} > ${key}` : key;
          this.collectErrors(childControl, childPath, errors);
        }
      });
    }

    if (control instanceof FormArray) {
      control.controls.forEach((ctrl, index) => {
        const childPath = path ? `${path}[${index}]` : `Item ${index + 1}`;
        this.collectErrors(ctrl, childPath, errors);
      });
    }
  }

  private getErrorMessage(key: string, value: any, path: string): string {
    const fieldName = path || 'Field';

    switch (key) {
      case 'required':
        return `${fieldName} is required`;
      case 'minlength':
        return `${fieldName} must be at least ${value.requiredLength} characters`;
      case 'oneCorrectAnswer':
        return 'Please select exactly one correct answer';
      case 'minTwoAnswers':
        return 'Please add at least two answers';
      default:
        return `${fieldName} has error: ${key}`;
    }
  }
}
