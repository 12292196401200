import { patchState, signalState } from '@ngrx/signals';
import { effect, inject, Injectable, signal } from "@angular/core";
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { tapResponse } from '@ngrx/operators';
import { catchError, exhaustMap, pipe, tap, throwError } from 'rxjs';
import { QuiztimeApiService } from '../services/quiztime-api.service';
import { User } from '../models/user.interface';
import { Token, TokenStorageService } from '../services/token-storage/token-storage.service';
import { LOCAL_STORAGE_REDIRECT_URL, REDIRECT_FOR_LOGIN_URL, REFRESH_TOKEN_FROM_CATALOG_INTERVAL } from '../constants';

type AuthState = {
  user: User | null,
  is_loading: boolean,
}

const initialState: AuthState = {
  user: null,
  is_loading: false,
}

@Injectable({
  providedIn: 'root'
})
export class AuthStore {
  readonly #apiService = inject(QuiztimeApiService);
  readonly #tokenService = inject(TokenStorageService);
  readonly #state = signalState(initialState);
  readonly user = this.#state.user;
  readonly isLoading = this.#state.is_loading;
  refreshFn = signal<any>(null);

  readonly updateUser = (user: User) => {
    patchState(this.#state, { user });
    this.ensureRefreshRunning();
  }

  updateUser$ = this.#apiService.getUserInfo().pipe(
    tap(() => patchState(this.#state, { is_loading: true })),
      exhaustMap(() => {
        return this.#apiService.getUserInfo().pipe(
          tapResponse({
            next: (user: User) => this.updateUser(user),
            error: () => patchState(this.#state, { user: null }),
            complete: () => patchState(this.#state, { is_loading: false }),
          })
        )
      })
  );

  readonly updateUserRx = rxMethod<void>(
    pipe(
      tap(() => patchState(this.#state, { is_loading: true })),
      exhaustMap(() => {
        return this.#apiService.getUserInfo().pipe(
          tapResponse({
            next: (user: User) => this.updateUser(user),
            error: () => {
              patchState(this.#state, { user: null });
              this.cancelRefresh();
            },
            complete: () => patchState(this.#state, { is_loading: false }),
          })
        )
      })
    )
  )

  constructor() {
    if (localStorage.getItem('user') !== 'undefined' && !this.user()) {
      this.updateUser(JSON.parse(localStorage.getItem('user')!));
    }
    effect(() => {
      localStorage.setItem('user', JSON.stringify(this.user()));
    });
  }

  // I'm fairly convinced this belongs elsewhere, but I'm not sure where yet.
  ensureRefreshRunning() {
    if (!this.refreshFn()) {
      const refresh = setInterval(() => {
        if (this.user()?.ltiUser.email !== undefined) {
          this.#apiService
            .getRefreshToken(this.user()?.ltiUser?.email as string)
            .pipe(
              catchError(() => this.handleRefreshError()),
              tap((resp: Token) => this.#tokenService.setToken(resp))
            )
            .subscribe();
        }
      }, REFRESH_TOKEN_FROM_CATALOG_INTERVAL); // 10 minutes
      this.refreshFn.set(refresh);
    }
  }

  handleRefreshError = () => {
    localStorage.setItem(LOCAL_STORAGE_REDIRECT_URL, window.location.pathname);
    console.error('TODO - a better experience here would be to show a warning to the user prompting a new login.');
    this.cancelRefresh();
    window.location.href = REDIRECT_FOR_LOGIN_URL;
    return throwError(() => new Error('Could not refresh token, redirecting to login.'));
  }

  cancelRefresh() {
    clearInterval(this.refreshFn());
    this.refreshFn.set(null);
  }
}
