import { Component, Input, Output, EventEmitter, inject, OnInit, CUSTOM_ELEMENTS_SCHEMA, computed, Signal } from '@angular/core';
import { QuizQuestionsStateService } from '../../../quiz-questions-state.service';
import { v4 as uuidv4 } from 'uuid';
import { FormBuilder, FormGroup, FormArray, Validators, ValidatorFn, AbstractControl, ValidationErrors, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { WysiwygEditorComponent } from '../../../../../shared-components/wysiwyg-editor/wysiwyg-editor.component';
import { LoadingButtonComponent } from '../../../../../shared-components/loading-button/loading-button.component';
import { AnswerItemComponent } from './answer-item.component';
import { QuestionAnswer } from '../../../../../models/question-answer.interface';
import { Question } from '../../../../../models/question.interface';
import { ConfirmationModalComponent } from '../../../../../shared-components/confirmation-modal/confirmation-modal.component';
import { FormErrorDisplayComponent } from '../../../../../shared-components/form-errors/form-errors.component';
import { tap } from 'rxjs';

@Component({
  selector: 'app-question-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    WysiwygEditorComponent,
    LoadingButtonComponent,
    AnswerItemComponent,
    ConfirmationModalComponent,
    FormErrorDisplayComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  template: `
    <app-form-error-display [form]="questionForm"></app-form-error-display>
    <form [formGroup]="questionForm" (ngSubmit)="handleSubmit()">
      <div class="form-group">
        <label for="type">* Type</label>
        <select formControlName="type" class="form-select" aria-label="Question Type">
          <option [ngValue]="'MultipleChoice'" selected readonly>Multiple choice</option>
        </select>
      </div>
      <div class="mt-4 form-group">
        <label for="text">* Question</label>
        <app-wysiwyg-editor
          [parentForm]="questionForm"
          [formControlName]="'text'"
          [id]="'question-' + index + '-description'"
          [placeholder]="'Question text...'"
        ></app-wysiwyg-editor>
      </div>
      <div class="mt-4" formArrayName="answers">
        <p>* Answers</p>
        @for (answer of answers.controls; track $index; let j = $index) {
          <app-answer-item
            [formGroup]="getAnswerFormGroup(j)"
            [index]="j"
            (isCorrectChange)="onIsCorrectChange(j)"
            (deleteAnswer)="deleteAnswer(j)"
          >
            @if(!getAnswerFormGroup(j).get('is_correct')?.value) {
              <label>Possible Answer</label>
            }
            @else {
              <label class="badge bg-success"> &nbsp; Correct Answer</label>
            }
          </app-answer-item>

        }
        <button (click)="addAnswer()" type="button" class="btn btn-link text-decoration-none">+ Add Answer</button>
      </div>
      <div class="mt-4 form-group">
        <label for="questionText">* Explanation</label>
        <app-wysiwyg-editor
          [parentForm]="questionForm"
          [formControlName]="'explanation'"
          [id]="'question-explanation'"
          [placeholder]="'The explanation is shown after an answer is submitted...'"
        ></app-wysiwyg-editor>
      </div>
      <div class="mt-2">
          <app-loading-button
            [type]="'submit'"
            [disabled]="this.questionForm.invalid || this.questionForm.pristine"
            [loading]="this.isSubmitting()"
            [testId]="'question-form__submit-button'">
            {{ question.provider_data === null ? 'Add' : 'Save Edits' }}
          </app-loading-button>
          @if(question.provider_data !== null) {
            <button type="button" class="btn btn-danger ms-2" (click)="alert('Delete functionality is implemented in a future card.  Devs should note that the modal confirmation is already implemented, but commented out.')">Delete</button>
            <!-- <app-confirmation-modal
              class="ms-2"
              (isAffirmative)="deleteConfirmed(this.question)"
              [triggerButtonColor]="'danger'">
              <modal-header>Confirm Deletion</modal-header>
              <modal-body>Are you sure you want to delete this question?</modal-body>
              <modal-affirmative>Yes, Delete</modal-affirmative>
              <modal-negative>No, Cancel</modal-negative>
              <modal-trigger-inner>Delete</modal-trigger-inner>
            </app-confirmation-modal> -->
          }
      </div>
    </form>
  `
})
export class QuestionFormComponent implements OnInit {
  @Input() question!: Question;
  @Input() index!: number;

  private formBuilder = inject(FormBuilder);
  private stateService = inject(QuizQuestionsStateService);

  questionForm!: FormGroup;
  isSubmitting: Signal<boolean> = computed(() => this.stateService.workingWithApi());

  ngOnInit(): void {
    this.questionForm = this.formBuilder.group(
      {
        type: ['MultipleChoice'],
        text: [this.question.text, [Validators.required]],
        answers: this.formBuilder.array(
          this.question.answers.map((answer: QuestionAnswer) =>
            this.formBuilder.group({
              text: [answer.text, [Validators.required, Validators.minLength(1)]],
              is_correct: [answer.is_correct],
              provider_data: answer.provider_data ? this.formBuilder.group({
                id: [answer.provider_data.id]
              }) : null
            })
          ),
          [this.oneCorrectAnswerValidator, this.minTwoAnswersValidator]
        ),
        explanation: [this.question.explanation],
      }
    );
    this.answers.valueChanges.subscribe(() => {
      this.updateIsCorrectCheckboxes();
      // this.logFormErrors();
    });

    this.updateIsCorrectCheckboxes();
  }

  handleSubmit() {
    const updatedQuestion = {
      ...this.question,
      ...this.questionForm.value
    };
    if (this.question.internal_id !== null) {
      this.stateService
        .saveNewQuestion(updatedQuestion)
        .pipe(
          tap(() => this.questionForm.markAsPristine()),
        )
        .subscribe();
    }
    else {
      this.stateService.saveUpdatedQuestion(updatedQuestion);
    }
  }

  get answers() {
    return this.questionForm.get('answers') as FormArray;
  }

  getAnswerFormGroup(index: number): FormGroup {
    return this.answers.at(index) as FormGroup;
  }

  addAnswer() {
    const newAnswer = this.formBuilder.group({
      text: [null, Validators.required],
      is_correct: [false],
      provider_data: null
    });
    this.answers.push(newAnswer);
  }

  public deleteAnswer(index: number) {
    this.answers.removeAt(index);
    this.questionForm.markAsDirty();
  }

  onIsCorrectChange(index: number) {
    const isCorrect = this.answers.at(index).get('is_correct')?.value;
    if (isCorrect) {
      this.answers.controls.forEach((control, i) => {
        if (i !== index) {
          control.get('is_correct')?.disable({ emitEvent: false });
        }
      });
    } else {
      this.answers.controls.forEach(control => {
        control.get('is_correct')?.enable({ emitEvent: false });
      });
    }
  }

  updateIsCorrectCheckboxes() {
    const correctAnswers = this.answers.controls.filter(control => control.get('is_correct')?.value);
    if (correctAnswers.length === 1) {
      this.answers.controls.forEach(control => {
        if (!control.get('is_correct')?.value) {
          control.get('is_correct')?.disable({ emitEvent: false });
        }
      });
    } else {
      this.answers.controls.forEach(control => {
        control.get('is_correct')?.enable({ emitEvent: false });
      });
    }
  }

  private oneCorrectAnswerValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const formArray = control as FormArray;
    const correctAnswers = formArray.controls.filter(control => control.get('is_correct')?.value);
    return correctAnswers.length === 1 ? null : { oneCorrectAnswer: true };
  }

  private minTwoAnswersValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const formArray = control as FormArray;
    return formArray.controls.length >= 2 ? null : { minTwoAnswers: true };
  }

  onDelete() {
    // Logic to open the confirmation modal
  }

  deleteConfirmed(question: Question) {
    this.stateService.deleteQuestion(question);
  }

  alert(message: string) {
    alert(message);
  }
}
