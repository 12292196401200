import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { User } from '../../../../models/user.interface';
import { AdministratorActionsComponent } from './administrator-actions/administrator-actions.component';
import { Enrollment } from '../../../../models/enrollment.interface';
import { Role } from '../../../../models/role.enum';
import { EnrollmentRequest } from '../../../../models/enrollment-request.interface';
import { QuizAdministratorView } from '../../../../models/views/quiz-administrator-view.interface';


/**
 * The Quiz settings should include a listing of all administrators for this Quiz. The listing should include the following information:

      First Name
      Last Name
      Email address - the value should be a mailto: link for this email address
      An icon that indicates the user that is the Owner/Creator of the Quiz. This icon will only appear for the user that created the course.
      An icon that when clicked should delete that user as a co-administrator.
        The user must confirm that they want to delete that administrator prior to removing them from the course. This icon will not display for the Owner of the Quiz.
 */

@Component({
  selector: 'app-quiz-administrators-list',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, AdministratorActionsComponent],
  styleUrl: './quiz-administrators-list.component.scss',
  template: `
    @if(!administrators.length) {
      <div class="alert alert-warning">
        No administrators have been added to this quiz.
      </div>
    } @else {
      <p class="mb-0">Quiz Administrators:</p>
      <div class="border rounded p-1 pb-0 table-responsive">
        <table class="table pb-0 mb-0">
          <thead>
            <tr>
              <th class="px-3 py-3">Name</th>
              <th class="px-3 py-3">Email</th>
              <th class="px-3 py-3">Role</th>
              <th class="px-3 py-3"></th>
            </tr>
          </thead>
          <tbody>
            @for(admin of administrators; track admin.email) {
              <tr>
                <td class="px-3 py-3">{{ admin?.firstName }} {{ admin?.lastName }}</td>
                <td class="px-3 py-3">{{ admin.email }}</td>
                <td class="px-3 py-3">
                  @if(isOwner(admin)) {
                    <span class="badge rounded-pill bg-success text-light fw-light">Owner</span>
                  } @else {
                    <span class="badge rounded-pill bg-none border border-success text-dark fw-light">Admin</span>
                  }
                </td>
                <td class="px-3 py-2">
                  <app-administrator-actions
                    [enrollment]=admin
                    [isOwner]="isOwner(admin)"
                    (administratorRemoved)="this.administratorRemoved.emit($event)">
                  </app-administrator-actions>
                </td>
              </tr>
            }
            @for(pending of pendingAdministrators; track pending.email) {
              <tr>
                <td class="px-3 py-3"></td>
                <td class="px-3 py-3">{{ pending.email }}</td>
                <td class="px-3 py-3">
                  <span class="badge rounded-pill bg-none border border-success text-dark fw-light">Admin</span>
                </td>
                <td class="px-3 py-2">
                  @if (pending.isWorkingAdding) {
                    <span data-testid="administrator-pending-work" class="ms-2 mt-2 text-primary spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  }
                  @else {
                    <app-administrator-actions
                      [enrollment]=pending
                      [isOwner]="false"
                      [isPending]="true"
                      (administratorRemoved)="this.administratorRemoved.emit($event)">
                    </app-administrator-actions>
                  }
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    }
  `
})
export class QuizAdministratorsListComponent {
  @Input() administrators!: QuizAdministratorView[];
  @Input() owner!: QuizAdministratorView;
  @Input() pendingAdministrators!: QuizAdministratorView[];
  @Output() administratorRemoved = new EventEmitter<string>();

  isOwner(admin: QuizAdministratorView): boolean {
    return admin.role === Role.Owner;
  }
}
