import { ChangeDetectionStrategy, Component, signal, inject } from '@angular/core';
import { AuthStore } from '../../../store/auth.store';
import { catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { LOCAL_STORAGE_REDIRECT_URL } from '../../../constants';
import { Router } from '@angular/router';
import { NgClass } from '@angular/common';
import { QuiztimeApiService } from '../../../services/quiztime-api.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-login-required',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrl: './login-required.component.scss',
  template: `
  <div class="login-required-container d-flex align-items-start pt-5 justify-content-center min-vh-100 bg-light">
    <div class="card shadow-sm border-0 login-card">
      <div class="card-body p-4 p-sm-5">
        <div class="text-center mb-4">
          <!-- You can replace this with your actual logo -->
          <div class="logo-placeholder mb-3">
            <i class="bi bi-shield-lock fs-1 text-primary"></i>
          </div>
          <h2 class="fw-bold">Authentication</h2>
        </div>

        @if (loading()) {
          <div class="text-center py-4">
            <div class="spinner-border text-primary mb-3" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <p class="text-secondary mb-0">Verifying your credentials...</p>
            <small class="text-muted">You'll be redirected automatically</small>
          </div>
        } @else {
          <div class="alert alert-danger d-flex align-items-center" role="alert">
            <i class="bi bi-exclamation-circle-fill me-2"></i>
            <div>
              <h5>Authentication Required</h5>
              <p class="mb-0">You need to be logged in to access this page.</p>
            </div>
          </div>
          <div class="d-grid gap-2 mt-4">
            <button class="btn btn-primary" (click)="retryAuthentication()">Try Again</button>
          </div>
        }
      </div>
    </div>
  </div>
  `
})
export class LoginRequiredComponent {
  private authStore = inject(AuthStore);
  private router = inject(Router);
  private apiService = inject(QuiztimeApiService);

  loading = signal<boolean>(true);

  constructor() {
    this.authenticate();
  }

  authenticate() {
    this.loading.set(true);
    this.authStore.updateUser$.pipe(
      catchError(err => {
        this.loading.set(false);
        return of([]);
      }),
      finalize(() => {
        // Ensure loading is set to false after a certain timeout
        // in case the subscription doesn't complete or error out
        setTimeout(() => this.loading.set(false), 15000);
      })
    )
    .subscribe(() => {
      const redirectTo = localStorage.getItem(LOCAL_STORAGE_REDIRECT_URL) ?? '/';
      this.router.navigate([redirectTo]);
    });
  }

  retryAuthentication() {
    window.location.href = `${environment.catalogUrl}/my-quizzes`;
  }
}
