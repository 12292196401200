import { CanDeactivate, CanDeactivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfirmationModalComponent } from '../shared-components/confirmation-modal/confirmation-modal.component';
import { inject, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDeactivateModalComponent } from '../shared-components/confirm-deactivate-modal/confirm-deactivate-modal.component';


export interface IFormDirtyGuard {
  canDeactivate: () => boolean;
  saveConfirmed: () => void;
}


export const FormDirtyGuard: CanDeactivateFn<IFormDirtyGuard> = (component: IFormDirtyGuard) => {
  const modalService = inject(NgbModal);
  if (component.canDeactivate()) {
    return true;
  }

  const modalRef = modalService.open(ConfirmDeactivateModalComponent);
  modalRef.componentInstance.heading = 'Save Changes?';
  modalRef.componentInstance.message = 'This quiz has been modified. Do you want to save changes?';

  return modalRef.result.then(
    (result) => {
      if (result === 'save') {
        component.saveConfirmed();
        return false;
      }

      return result === 'leave';
    },
    () => false
  );
};
