import { inject, Injectable, signal } from '@angular/core';
import { catchError, concat, finalize, Observable, of, tap } from 'rxjs';
import { Question } from '../../models/question.interface';
import { QuiztimeApiService } from '../../services/quiztime-api.service';
import { DeliveryConfiguration } from '../../models/quizzes/delivery-configuration.interface';
import { ToastService } from '../../services/toast.service';
import { QuestionType } from '../../models/enums/question-type.enum';
import { QuestionAnswer } from '../../models/question-answer.interface';

@Injectable({
  providedIn: 'root'
})
export class QuizQuestionsStateService {
  private quiztimeApi = inject(QuiztimeApiService);
  private toastService = inject(ToastService);

  formIsDirty = signal<boolean>(false);

  questions = signal<Question[]>([]);
  deliveryConfiguration = signal<DeliveryConfiguration | null>(null);
  deliveryConfigurationInvalid = signal<boolean>(false);

  failedApiProcesses = signal<any[]>([]);
  questionUpdated = signal<Question | null>(null);

  submittingQuestions = signal<Map<string, boolean>>(new Map());
  loadingQuestions = signal<boolean>(false);
  loadingQuestionsError = signal<boolean|string>(false);

  loadingConfiguration = signal<boolean>(false);
  loadingConfigurationError = signal<boolean|string>(false);
  loadingConfigurationNotFound = signal<boolean>(false);
  workingWithApi = signal<boolean>(false);
  quizGuid = '';
  hasUnsavedQuestion = signal<boolean>(false);
  openQuestions = signal<number[]>([]);

  initiateQuizQuestionConfigurationState(quizGuid: string) {
    this.quizGuid = quizGuid;
    this.loadingConfiguration.set(true);
    this.loadingQuestions.set(true)

    const quizConfiguration = this.quiztimeApi.getQuizDeliveryConfiguration(quizGuid).pipe(
      tap(configuration => this.deliveryConfiguration.set(configuration)),
      catchError(err => {
        if (err.status === 404) {
          this.deliveryConfiguration.set({
            quiz_guid: quizGuid,
            quiz_type: 'on_demand',
            is_active: false,
            questions_expiration: 172800,
            send_last_chance: false,
            immediate_reattempts: true,
            delivery_rule: 'FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR'
          });
          this.loadingConfigurationNotFound.set(true);
          return of([]);
        }
        else {
          this.loadingConfigurationError.set('Failed to load the configuration, please try again later.');
          return of([]);
        }
      }),
      finalize(() => this.loadingConfiguration.set(false))
    );
    const quizQuestions = this.quiztimeApi.getQuizQuestions(quizGuid).pipe(
      catchError(err => {
        this.loadingQuestionsError.set('Failed to load questions, please try again later.');
        return of([]);
      }),
      tap(questions => this.loadQuestions(questions)),
      finalize(() => this.loadingQuestions.set(false))
    );

    return concat(quizConfiguration, quizQuestions);
  }

  openQuestion(index: number) {
    this.openQuestions.update(list => {
      if (list.includes(index)) {
        return list;
      }
      return [...list, index];
    });
  }

  closeQuestion(index: number) {
    this.openQuestions.update(list => list.filter(i => i !== index));
  }

  // Load initial questions
  loadQuestions(questions: Question[]) {
    const questionsWithIds = questions.map(question => ({
      ...question,
      internal_id: question.internal_id || Math.random().toString(36).substring(2) // Unique ID generator
    }));
    this.questions.set(questionsWithIds);
  }

  addNewQuestion() {
    console.log('Adding new question', this.questions());
    const nextQuestionNumber = this.questions().length + 1;
    const newQuestion: Question = {
      internal_id: crypto.randomUUID().toString(),
      name: `Question ${nextQuestionNumber}`,
      text: '',
      position: nextQuestionNumber,
      type: QuestionType.multiple_choice, // Example type, adjust as needed
      answers: [
        { text: '', is_correct: false } as QuestionAnswer,
        { text: '', is_correct: false } as QuestionAnswer,
      ],
      provider_data: null,
      explanation: null,
    };
    this.questions.update(questions => [...questions, newQuestion]);
    this.hasUnsavedQuestion.set(true);
    this.openQuestions.update(list => [nextQuestionNumber - 1]);
  }

  removeQuestion(question: Question) {
    this.questions.update(questions => questions.filter(q => {
      if (question.provider_data != null) {
        return q.provider_data?.id !== question.provider_data.id;
      }

      return q?.internal_id !== question.internal_id
    }));

    if (question.provider_data === null) {
      this.hasUnsavedQuestion.set(false);
    }

    this.updateQuestionPositions();
    this.openQuestions.set([...this.openQuestions().filter(index => index !== question.position - 1)]);
  }

  updateQuestionPositions() {
    const questions = this.questions();
    // Update question positions
    // reconfigure all question positions by updating the positiona nd name property
    const updated = questions.map((question, index) => {
      question.position = index + 1;
      question.name = `Question ${index + 1}`;
      return question;
    });
    this.questions.set(updated);
  }

  saveUpdatedQuestion(updatedQuestion: Question) {
    this.workingWithApi.set(true);
    this.doUpdateQuestion(updatedQuestion)
      .pipe(
        catchError(err => {
          console.error('Failed to update question', err);
          this.failedApiProcesses.update(failures => [...failures, updatedQuestion]);
          return of(updatedQuestion);
        }),
        finalize(() => {
          this.workingWithApi.set(false);
          this.toastService.success('Question Saved.');
        })
      ).subscribe();
  }

  saveNewQuestion(newQuestion: Question) : Observable<Question> {
    this.workingWithApi.set(true);
    return this.doUpdateQuestion(newQuestion)
      .pipe(
        tap((savedQuestion: Question) => {
          // Update the question with the saved question
          this.questions.update(questions => {
            return questions.map(question => {
              if (question.name === newQuestion.name) {
                return savedQuestion;
              }
              return question;
            });
          });
          // Reset unsaved question flag after saving
          this.hasUnsavedQuestion.set(false);
        }),
        catchError(err => {
          console.error('Failed to update question', err);
          this.failedApiProcesses.update(failures => [...failures, newQuestion]);
          return of(newQuestion);
        }),
        finalize(() => {
          this.workingWithApi.set(false)
          this.toastService.success('Question Saved.');
        })
      );
  }

  deleteQuestion(question: Question) {
    console.log('Deleting question:', question);
    this.doDeleteQuestion(question);
  }

  setDeliveryConfigurationUpdated(updatedConfiguration: DeliveryConfiguration) {
    this.deliveryConfiguration.set(updatedConfiguration);
    this.formIsDirty.set(true);
  }

  processUpdatedDeliveryConfiguration() {
    this.workingWithApi.set(true);
    const config = this.deliveryConfiguration();
    if (config == null) {
      this.loadingConfigurationError.set('No configuration to update.');
      this.workingWithApi.set(false);
      return of();
    }
    return this.doUpdateDeliveryConfiguration(config)
      .pipe(
        catchError(err => {
          this.loadingConfigurationError.set('Failed to update the configuration, please try again later.');
          return of(this.deliveryConfiguration());
        }),
        tap(updatedConfiguration => this.deliveryConfiguration.set(updatedConfiguration)),
        finalize(() => {
          this.workingWithApi.set(false);
          this.toastService.success('Quiz Configuration Saved.');
        })
      );
  }

  resetState() {
    this.formIsDirty.set(false);
    this.questions.set([]);
    this.deliveryConfiguration.set(null);
    this.deliveryConfigurationInvalid.set(false);
    this.failedApiProcesses.set([]);
    this.questionUpdated.set(null);
    this.submittingQuestions.set(new Map());
    this.loadingQuestions.set(false);
    this.loadingQuestionsError.set(false);
    this.loadingConfiguration.set(false);
    this.loadingConfigurationError.set(false);
    this.loadingConfigurationNotFound.set(false);
    this.workingWithApi.set(false);
    this.hasUnsavedQuestion.set(false);
  }

  private doUpdateDeliveryConfiguration(config: DeliveryConfiguration) {
    return this.quiztimeApi.putQuizDeliveryConfiguration(this.quizGuid, config)
      .pipe(
        catchError(err => {
          console.error('Failed to update delivery configuration', err);
          this.failedApiProcesses.update(failures => [...failures, config]);
          throw err;
        }),
        finalize(() => this.workingWithApi.set(false))
      )
  }

  private doDeleteQuestion(question: Question) {
    console.error('Deleting question not implemented yet:', question);
  }

  private doUpdateQuestion(updatedQuestion: Question) : Observable<Question> {
    return this.quiztimeApi.addOrUpdateQuestion(this.quizGuid, updatedQuestion);
  }
}
