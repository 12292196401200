import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-quiz-error',
  standalone: true,
  imports: [],
  template: `
    <div class="alert alert-danger" role="alert">
      {{ error }}
    </div>
  `
})
export class QuizErrorComponent {
  @Input() error: string = 'We encountered an error while creating the quiz. Please try again.';
}
