import { Component } from "@angular/core";
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: "app-quiz-details-form-loader",
  imports: [NgxSkeletonLoaderModule],
  template: `
    <div class="item">
      * Name:
      <ngx-skeleton-loader count="1" appearance="line" />
      Quiz Administrators:
      <ngx-skeleton-loader count="3" appearance="line" />
      Description:
      <ngx-skeleton-loader count="1" [theme]="{height: '100px'}" appearance="line" />
      Banner:
      <ngx-skeleton-loader count="1" [theme]="{height: '100px'}" appearance="line" />
    </div>
  `,
  standalone: true,
})
export class QuizDetailsFormLoaderComponent {}
