import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deliveryTime',
  standalone: true,
})
export class DeliveryTimePipe implements PipeTransform {
  transform(
    utcTime: string | undefined,
    timezone?: string,
    format: 'display' | 'local' = 'display'
  ): string {
    if (!utcTime) return 'N/A';

    try {
      // Create date object for today with the UTC time
      const [hours, minutes] = utcTime.split(':');
      const today = new Date();
      const utcDate = new Date(
        Date.UTC(
          today.getUTCFullYear(),
          today.getUTCMonth(),
          today.getUTCDate(),
          parseInt(hours),
          parseInt(minutes)
        )
      );

      if (format === 'local') {
        const localTime = utcDate.toLocaleTimeString([], {
          hour: 'numeric',
          minute: '2-digit',
          hour12: true,
        });
        const localZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return `${localTime} (${localZone})`;
      }

      // Rest of display format logic
      const timeString = utcDate.toLocaleTimeString([], {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
        timeZone: timezone || undefined,
      });

      const tzAbbr = timezone
        ? new Intl.DateTimeFormat('en-US', {
            timeZone: timezone,
            timeZoneName: 'short',
          })
            .formatToParts(utcDate)
            .find((part) => part.type === 'timeZoneName')?.value
        : '';

      return timeString + (tzAbbr ? ` (${tzAbbr})` : '');
    } catch (error) {
      console.error('Error formatting delivery time:', error);
      return 'Invalid Time';
    }
  }
}
