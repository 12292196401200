import { Directive, HostListener, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormDirtyService } from '../../services/form-dirty.service';

/**
 * This satisifes the requirement for a directive that will protect a user from navigating away from a form with unsaved changes.
 * The biz team has requested that if a user navigates within the quiz details, e.g. going from announcements to questions,
 *  that they are prompted to save their changes via a modal.
 * However, if a user clicks an external link, they should just see the default browser confirm().
 * @see quiz-details-form.component.ts for implementation example.
 * @note that you will need to still implement IFormDirtyGuard to satisfy the first requirement.
 */

@Directive({
  selector: '[appFormNavigationConfirmation]',
  standalone: true
})
export class FormNavigationConfirmationDirective implements OnInit, OnDestroy {
  @Input() protectedForm: FormGroup | undefined;

  private formDirtyService = inject(FormDirtyService);

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: BeforeUnloadEvent): void {
    if (this.protectedForm?.dirty) {
      event.preventDefault();
      event.returnValue = '' as unknown as boolean;
    }
  }

  ngOnInit(): void {
    if (this.protectedForm) {
      this.formDirtyService.registerForm(this.protectedForm);
    }
  }

  ngOnDestroy(): void {
    if (this.protectedForm) {
      this.formDirtyService.unregisterForm(this.protectedForm);
    }
  }
}
