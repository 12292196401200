import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-toast-container',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="toast-container position-fixed top-0 end-0 p-3">
      @for (toast of toastService.toasts(); track toast.id) {
        <div
          class="toast show"
          role="alert"
          [ngClass]="'bg-' + getBootstrapClass(toast.type)"
          aria-live="assertive"
          aria-atomic="true">
          @if (toast.title) {
            <div class="toast-header">
              <strong class="me-auto">{{ toast.title }}</strong>
              <button
                type="button"
                class="btn-close"
                aria-label="Close"
                (click)="toastService.remove(toast.id!)">
              </button>
            </div>
          }
          <div class="toast-body text-white">
            {{ toast.message }}
          </div>
        </div>
      }
    </div>
  `,
  styles: [`
    .toast-container {
      z-index: 1200;
    }
  `]
})
export class ToastContainerComponent {
  toastService = inject(ToastService);

  getBootstrapClass(type: string): string {
    switch (type) {
      case 'success': return 'success';
      case 'error': return 'danger';
      case 'warning': return 'warning';
      case 'info': return 'info';
      default: return 'light';
    }
  }
}
