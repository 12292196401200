import { Injectable, inject, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CanDeactivateFn } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FormDirtyService {
  private dirtyForms = signal<FormGroup[]>([]);

  registerForm(form: FormGroup): void {
    this.dirtyForms.update(forms => [...forms, form]);

    // Clean up when form is no longer dirty
    form.statusChanges.subscribe(() => {
      if (!form.dirty) {
        this.unregisterForm(form);
      }
    });
  }

  unregisterForm(form: FormGroup): void {
    this.dirtyForms.update(forms => forms.filter(f => f !== form));
  }

  hasUnsavedChanges(): boolean {
    return this.dirtyForms().some(form => form.dirty);
  }
}

// Reusable CanDeactivate function for Angular routes
export const hasUnsavedChangesGuard: CanDeactivateFn<any> = (component) => {
  const formDirtyService = inject(FormDirtyService);

  if (formDirtyService.hasUnsavedChanges()) {
    return confirm('You have unsaved changes. Are you sure you want to leave?');
  }

  return true;
};
