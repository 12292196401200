import { Component, computed, inject, OnDestroy, OnInit, Signal, signal } from '@angular/core';
import { QuizDeliveryConfigurationFormComponent } from "../../components/quiz-delivery-configuration-form/quiz-delivery-configuration-form.component";
import { QuizQuestionsStateService } from '../../quiz-questions-state.service';
import { QuestionListComponent } from "../../components/question-list/question-list.component";
import { ActivatedRoute, Router } from '@angular/router';
import { concatMap, tap } from 'rxjs';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SignalDebugComponent } from "../../../../debug/signal-input/signal-debug.component";
import { LoadingButtonComponent } from "../../../../shared-components/loading-button/loading-button.component";
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-quiz-questions-configuration-container',
  standalone: true,
  imports: [QuizDeliveryConfigurationFormComponent, FormsModule, QuestionListComponent, NgxSkeletonLoaderModule, SignalDebugComponent, LoadingButtonComponent, NgbAccordionModule],
  template: `
      <app-quiz-delivery-configuration-form></app-quiz-delivery-configuration-form>
      <app-question-list></app-question-list>
      <div class="container d-flex justify-content-end">
        <form (ngSubmit)="handleSubmit()">
          <app-loading-button
            [type]="'submit'"
            [loading]="this.stateService.workingWithApi()"
            [disabled]="!this.stateService.formIsDirty() || this.stateService.deliveryConfigurationInvalid()"
          >Save & Continue</app-loading-button>
        </form>
      </div>
      @if (configurationSubmitError() != false) {
        <div class="container mt-0 d-flex flex-row justify-content-end">
          <p class="text-danger text-sm">{{ configurationSubmitError() }}</p>
        </div>
      }
      <div class="container mt-4">
        <div class="d-flex justify-content-end">
        <button class="btn btn-sm ms-auto" (click)="accordion.toggle('first')">Debug</button>
      </div>

      <div ngbAccordion #accordion="ngbAccordion">
        <div ngbAccordionItem="first" class="border-0" [collapsed]="false">
          <div ngbAccordionCollapse>
            <div ngbAccordionBody class="border-0">
              <ng-template>
                <div class="alert alert-info"> Debug Quiz Configuration State Service
                  <app-signal-debug [label]="'loadingConfiguration'" [value]="this.stateService.loadingConfiguration"></app-signal-debug>
                  <app-signal-debug [label]="'deliveryConfiguration'" [value]="this.stateService.deliveryConfiguration"></app-signal-debug>
                  <app-signal-debug [label]="'loadingConfigurationError'" [value]="this.stateService.loadingConfigurationError"></app-signal-debug>
                  <app-signal-debug [label]="'loadingQuestions'" [value]="this.stateService.loadingQuestions"></app-signal-debug>
                  <app-signal-debug [label]="'workingWithApi'" [value]="this.stateService.workingWithApi"></app-signal-debug>
                  <app-signal-debug [label]="'failedApiProcesses'" [value]="this.stateService.failedApiProcesses"></app-signal-debug>
                  <app-signal-debug [label]="'questions'" [value]="this.stateService.questions"></app-signal-debug>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class QuizQuestionsConfigurationContainerComponent implements OnInit, OnDestroy {
  private route = inject(ActivatedRoute);
  public stateService = inject(QuizQuestionsStateService);
  private router = inject(Router);

  quizGuid: string = '';
  configurationSubmitError: Signal<boolean|string>
    = computed(() => this.stateService.loadingConfigurationError());

  ngOnInit() {
    this.route.paramMap.pipe(
      tap(params => this.quizGuid = params.get('quizGuid')!),
      concatMap(() => this.stateService.initiateQuizQuestionConfigurationState(this.quizGuid)),
    ).subscribe(() => console.log('Quiz questions loaded', this.stateService.questions()));
  }

  ngOnDestroy(): void {
    this.stateService.resetState();
  }

  handleSubmit() {
    this.stateService
      .processUpdatedDeliveryConfiguration()
      .subscribe(() => this.router.navigate(['quizzes', this.quizGuid, 'learners']))
  }
}
