import { CommonModule } from '@angular/common';
import { Component, computed, effect, inject, OnInit, Signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, AbstractControl, ValidatorFn } from '@angular/forms';
import { QuizQuestionsStateService } from '../../quiz-questions-state.service';
import {RRule, rrulestr, Weekday} from 'rrule';
import { FormErrorDisplayComponent } from '../../../../shared-components/form-errors/form-errors.component';
import { NgxSkeletonLoaderComponent } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-quiz-delivery-configuration-form',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, FormErrorDisplayComponent, NgxSkeletonLoaderComponent],
  template: `
    <div class="container">
      <div class="row">
        <p class="border-bottom fs-5">Question Details</p>
      </div>
    </div>
    @if (this.isLoading()) {
      <div class="container">
        <div class="row">
          <ngx-skeleton-loader count="3" [theme]="{height: '30px'}" appearance="line" />
        </div>
      </div>
    }
    @else {
      @if (this.loadingConfigurationError() !== false) {
        <div class="container">
          <div class="alert alert-danger">
            {{ this.loadingConfigurationError() }}
          </div>
        </div>
      }
      @else {
        <div class="container">
        <app-form-error-display [form]="form"></app-form-error-display>
          <form [formGroup]="form">
            <div class="row mb-4">
              <label class=" form-label">* Expiration Policy:</label>
              <div class=" form-group d-flex">
                <div class="form-check me-2" *ngFor="let policy of expirationPolicies">
                  <label class="form-check-label" [for]="'policy-' + policy.seconds">{{ policy.label }}
                    <input class="form-check-input" type="radio" [value]="policy.seconds" formControlName="questions_expiration" id="policy-{{policy.seconds}}">
                  </label>
                </div>
              </div>
            </div>
            <div formGroupName="sendOnDays" class="row mb-4">
              <label class=" form-label">* Send on days:</label>
              <div class="">
                @for (day of sendOnDays; track $index) {
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" formControlName="{{day.key}}" id="{{day.key}}" />
                    <label class="form-check-label" for="{{day.key}}">{{ day.label }}</label>
                  </div>
                }
              </div>
            </div>
            <div class="row mb-4">
              <div class="form-check form-switch ps-3 ">
                <label class="form-check-label" for="allowImmediateReattempts">Allow Immediate Reattempts:</label>
                <input class="form-check-input float-none ms-3" type="checkbox" formControlName="immediate_reattempts" id="immediate-reattempts">
              </div>
            </div>

            <div class="row mb-4 d-flex justify-content-end">
              <div class="form-check form-switch ps-3 ">
                <label class="form-check-label" for="resendUnattemptedQuestions">Resend Unattempted Questions:</label>
                <input class="form-check-input float-none ms-3" type="checkbox" formControlName="send_last_chance" id="send-last-chance">
              </div>
            </div>
          </form>
        </div>
      }
  }
  `
})
export class QuizDeliveryConfigurationFormComponent implements OnInit {
  form!: FormGroup;
  expirationPolicies = [
    {label: 'Daily', seconds: 86400},
    {label: 'Two Days', seconds: 172800},
    {label: 'Weekly', seconds: 604800},
  ];

  configuration = computed(() => this.stateService.deliveryConfiguration());
  isLoading: Signal<boolean> = computed(() => this.stateService.loadingConfiguration());
  loadingConfigurationError = computed(() => this.stateService.loadingConfigurationError());
  sendOnDaysError = computed(() => this.stateService.deliveryConfigurationInvalid());

  private stateService:QuizQuestionsStateService = inject(QuizQuestionsStateService);
  private formBuilder: FormBuilder = inject(FormBuilder);
  private rrule!: RRule;
  private formEffect = effect(() => {
    if (!this.isLoading()) {
      this.initializeForm();
    }
  });

  ngOnInit(): void {
    if (!this.isLoading() && this.configuration()) {
      this.initializeForm();
    }
  }

  private initializeForm(): void {
    if (this.form) return;

    if (!this.isLoading()) {
      const currentConfig = this.configuration();
      this.rrule = rrulestr(currentConfig?.delivery_rule || 'FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR');
      const byWeekday = new Set<string>((this.rrule.options.byweekday || []).map(day => day.toString()));
      this.form = this.formBuilder.group({
        quiz_type: ['on_demand'],
        questions_expiration: currentConfig?.questions_expiration,
        immediate_reattempts: currentConfig?.immediate_reattempts,
        send_last_chance: currentConfig?.send_last_chance,
        sendOnDays: this.formBuilder.group({
          MO: this.formBuilder.control(byWeekday.has('0')),
          TU: this.formBuilder.control(byWeekday.has('1')),
          WE: this.formBuilder.control(byWeekday.has('2')),
          TH: this.formBuilder.control(byWeekday.has('3')),
          FR: this.formBuilder.control(byWeekday.has('4')),
          SA: this.formBuilder.control(byWeekday.has('5')),
          SU: this.formBuilder.control(byWeekday.has('6')),
        }, { validators: this.atLeastOneDayValidator })
      });
    }
    // I am anti-this in a major way.  However, the design calls for this form to be a section of the
    // the questions form.  Therefore, we need to bubble this up to the parent, which isn't actually
    // a form, so that we can know whether or not the non-form parent should be allowed to submit.
    this.form?.valueChanges.subscribe(value => {
      Object.keys(this.form.controls).forEach(key => {
        const controlErrors = this.form.get(key)?.errors;
        if (controlErrors) {
          this.stateService.deliveryConfigurationInvalid.set(true);
        }
        else {
          this.stateService.deliveryConfigurationInvalid.set(false);
        }
      });
      const activeDays = Object.entries(value.sendOnDays)
        .filter(([key, dayValue]) => dayValue)
        .map(([key]) => {
          const day = this.weekdayDefinitions.find(day => day.key === key);
          return day?.value;
        })
        .filter(value => value !== undefined);

      let delivery_rule = new RRule({
        ...this.rrule.origOptions,
        byweekday: activeDays
      }).toString();

      this.stateService.setDeliveryConfigurationUpdated({
        ...this.configuration(),
        ...value,
        delivery_rule
      })
    });
  }

  get weekdayDefinitions() {
    return [
      { key: 'MO', label: 'Monday', value: 0 },
      { key: 'TU', label: 'Tuesday', value: 1 },
      { key: 'WE', label: 'Wednesday', value: 2 },
      { key: 'TH', label: 'Thursday', value: 3 },
      { key: 'FR', label: 'Friday', value: 4 },
      { key: 'SA', label: 'Saturday', value: 5 },
      { key: 'SU', label: 'Sunday', value: 6 },
    ];
  }

  get sendOnDays() {
    return this.weekdayDefinitions.map(({ key, label }) => ({ key, label }));
  }

  private atLeastOneDayValidator: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
    const days = Object.values(control.value);
    return days.some(v => v) ? null : { requireOneDay: true };
  }
}
