import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { ReactiveFormsModule, FormsModule, FormGroup, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';
import { TableService } from '../../services/table.service';

const WYSIWYG_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => WysiwygEditorComponent),
  multi: true
}

@Component({
  selector: 'app-wysiwyg-editor',
  standalone: true,
  imports: [ReactiveFormsModule, QuillEditorComponent, FormsModule],
  providers: [WYSIWYG_CONTROL_ACCESSOR],
  styleUrl: './wysiwyg-editor.component.scss',
  template: `
    <ng-content select="label"></ng-content>
    <div [formGroup]="parentForm" [attr.data-testid]="id + '-test'">
      <quill-editor (onEditorCreated)="getEditorInstance($event)"
      [id]="id"
      [placeholder]="placeholder"
      [formControlName]="formControlName"
      [className]="'wysiwyg-editor-component'"
      [modules]="{toolbar: toolbarOptions}"
      ></quill-editor>
    </div>`
})
export class WysiwygEditorComponent implements ControlValueAccessor {
  @Input() placeholder = 'Enter text here';
  @Input() id = 'editor';
  @Input() parentForm!: FormGroup;
  @Input() formControlName!: string;
  @Output() imageUploadRequested = new EventEmitter<{
    file: File;
    range: any;
    callback: (url: string) => void;
  }>();
  quillEditorRef: any;

  constructor(
    private tableService: TableService
  ) {}

  getEditorInstance(editorInstance: any) {
    this.quillEditorRef = editorInstance;
    const toolbar = this.quillEditorRef.getModule('toolbar');
    toolbar.addHandler('image', this.uploadImageHandler);
    // The Quill table button by default inserts a single row and nothing else, so overriding it for now.
    toolbar.addHandler('table', () => {
      this.tableService.promptTableSize((rows, cols) => {
        this.tableService.insertTable(rows, cols, this.quillEditorRef);
      });
    });
    toolbar.addHandler('table-cell-color-picker', this.tableCellColorPickerHandler);
  }

  uploadImageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      const file = input.files?.length ? input.files[0] : null;
      const range = this.quillEditorRef.getSelection();

      if (file) {
        this.imageUploadRequested.emit({
          file,
          range,
          callback: (url: string) => {
            this.quillEditorRef.insertEmbed(range.index, 'image', url);
          }
        });
      }
    }
  }

  tableCellColorPickerHandler = () => {
    alert('Not Yet Implemented');
  }

  toolbarConfigDefault = [
    ['bold', 'italic', 'underline'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'align': [] }],
    ['link', 'image', 'video'],
    // TODO: Figure out how to get an icon to show on the table cell color picker
    ['table', 'table-cell-color-picker']
  ];

  @Input() toolbarOptions = this.toolbarConfigDefault;

  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {}
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}
}
