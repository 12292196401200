import { Injectable, inject, signal } from '@angular/core';
// import { Toast, ToastService as NgxToastService } from 'ngx-bootstrap/toast';

export interface ToastInfo {
  id?: string;
  message: string;
  title?: string;
  delay?: number;
  type: 'success' | 'info' | 'warning' | 'error';
  autohide?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  // Using a signal to track active toasts
  toasts = signal<ToastInfo[]>([]);

  /**
   * Show a success toast notification
   */
  success(message: string, title?: string, delay: number = 5000): void {
    this.show({
      message,
      title,
      delay,
      type: 'success'
    });
  }

  /**
   * Show an info toast notification
   */
  info(message: string, title?: string, delay: number = 5000): void {
    this.show({
      message,
      title,
      delay,
      type: 'info'
    });
  }

  /**
   * Show a warning toast notification
   */
  warning(message: string, title?: string, delay: number = 5000): void {
    this.show({
      message,
      title,
      delay,
      type: 'warning'
    });
  }

  /**
   * Show an error toast notification
   */
  error(message: string, title?: string, delay: number = 5000): void {
    this.show({
      message,
      title,
      delay,
      type: 'error'
    });
  }

  /**
   * Show custom toast notification
   */
  show(toast: ToastInfo): void {
    // Generate a unique ID if one isn't provided
    const toastWithId = {
      ...toast,
      id: toast.id || `toast-${Date.now()}-${Math.floor(Math.random() * 1000)}`,
      autohide: toast.autohide !== false
    };

    // Add to the signal array
    this.toasts.update(currentToasts => [...currentToasts, toastWithId]);

    // Handle toast removal after delay or user action
    if (toastWithId.autohide && toastWithId.delay) {
      setTimeout(() => this.remove(toastWithId.id!), toastWithId.delay);
    }
  }

  /**
   * Remove a specific toast by id
   */
  remove(id: string): void {
    this.toasts.update(currentToasts =>
      currentToasts.filter(toast => toast.id !== id));
  }

  /**
   * Remove all toasts
   */
  clear(): void {
    this.toasts.set([]);
  }
}
