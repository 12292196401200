import { FormErrorDisplayComponent } from './../../shared-components/form-errors/form-errors.component';
import { Component, inject, OnInit, signal, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TimezoneSelectorComponent } from "../../shared-components/timezone-selector/timezone-selector.component";
import { TimeZone } from '@vvo/tzdb';
import { LoadingButtonComponent } from "../../shared-components/loading-button/loading-button.component";
import { catchError, concatMap, finalize, tap, Subscription } from 'rxjs';
import { QuiztimeApiService } from '../../services/quiztime-api.service';
import { ToastService } from '../../services/toast.service';
import { QuizDatesRequest } from '../../models/quizzes/quiz-dates-request.interface';
import { NgxSkeletonLoaderComponent } from 'ngx-skeleton-loader';
import { FormDebuggerComponent } from '../../debug/form-debugger/form-debugger.component';

@Component({
  selector: 'app-quiz-publish',
  standalone: true,
  imports: [
    CommonModule,
    FormErrorDisplayComponent,
    ReactiveFormsModule,
    TimezoneSelectorComponent,
    LoadingButtonComponent,
    NgxSkeletonLoaderComponent,
    FormDebuggerComponent
  ],
  template: `
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2 class="mb-4">Publication</h2>
        </div>
      </div>
      @if (loadingDates()) {
        <ngx-skeleton-loader count="3" appearance="line" />
      }
      @else {
        <app-form-error-display [form]="form"></app-form-error-display>
        <form [formGroup]="form">
          <div class="row">
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label for="start_date" class="form-label">Quiz Start Date</label>
                <input type="datetime-local" formControlName="start_date" id="start_date" class="form-control">
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label for="end_date" class="form-label">Quiz End Date</label>
                <input type="datetime-local" formControlName="end_date" id="end_date" class="form-control">
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label for="enrollment_end_date" class="form-label">
                  End of Enrollment Date
                  @if (form.get('end_date')?.value) {
                    <span class="text-danger">*</span>
                  }
                </label>
                <input type="datetime-local" formControlName="enrollment_end_date" id="enrollment_end_date" class="form-control">
                @if (calculatingEndDate()) {
                  <div class="text-muted mt-1">Calculating recommended date...</div>
                } @else if (enrollmentDateLoaded() && form.get('enrollment_end_date')?.errors?.['laterThanCalculated']) {
                  <div class="text-danger mt-1">
                    Enrollment end date cannot be later than the recommended date
                    @if (calculatedEnrollmentEndDate()) {
                      @let date = calculatedEnrollmentEndDate()!;
                      ({{ date.toLocaleString('en-US', {
                        month: 'numeric',
                        day: 'numeric',
                        year: '2-digit',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                      }) }})
                    }
                  </div>
                }
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <label for="default_timezone">Default Timezone</label>
            <input type="hidden" name="default_timezone" formControlName="time_zone" id="default_timezone" class="form-control">
            <app-timezone-selector
              [placeholder]="'Pick a timezone...'"
              [defaultTimeZone]="quizDates()?.time_zone ?? null"
              (timeZoneSelected)="onTimeZoneSelected($event)"
            ></app-timezone-selector>
          </div>
          <div class="row">
            <div class="mt-4 d-flex flex-row justify-content-end">
              <!-- future card should disable, or remove, the publish button if the quiz is published -->
              <app-loading-button
                class="me-2"
                [type]="'button'"
                [variant]="'success'"
                [loading]="isPublishing()"
                [disabled]="form.invalid || isSavingDraft()"
                (buttonClick)="onPublish()">
                Publish
              </app-loading-button>
              <app-loading-button
                [type]="'button'"
                [variant]="'primary'"
                [disabled]="form.invalid || isPublishing() || !form.dirty"
                [loading]="isSavingDraft()"
                (buttonClick)="onSaveDraft()">
                Save as Draft
              </app-loading-button>
            </div>
          </div>
        </form>
        <br><br><br>
        <app-form-debugger [form]="form"></app-form-debugger>
      }
    </div>
  `
})
export class QuizPublishComponent implements OnInit, OnDestroy {
  calculatedEnrollmentEndDate = signal<Date | null>(null);
  form!: FormGroup;
  quizGuid: string = '';
  quizDates = signal<QuizDatesRequest | null>(null);
  selectedTimeZone: TimeZone | null = null;
  calculatingEndDate = signal<boolean>(false);
  enrollmentDateLoaded = signal<boolean>(false);
  private subscriptions = new Subscription();
  private fb = inject(FormBuilder);
  private route = inject(ActivatedRoute);
  private quiztimeApi = inject(QuiztimeApiService);
  private toast = inject(ToastService);

  isPublishing = signal<boolean>(false);
  isSavingDraft = signal<boolean>(false);
  loadingDates = signal<boolean>(false);

  ngOnInit(): void {
    this.loadingDates.set(true);
    this.quizGuid = this.route.snapshot.paramMap.get('quizGuid') || '';
    this.quiztimeApi.getQuizDates(this.quizGuid).pipe(
      tap((quizDates: QuizDatesRequest) => {
        this.quizDates.set(quizDates);
        this.loadingDates.set(false);
      })
    ).subscribe(() => this.initDatesForm());
  }

  private initDatesForm(): void {
    // Check if enrollment end date exists and can be formatted
    const formattedEnrollmentDate = this.formatDateForInput(this.quizDates()?.enrollment_end_date);
    const hasEnrollmentEndDate = formattedEnrollmentDate !== null;

    this.form = this.fb.group({
      start_date: [this.formatDateForInput(this.quizDates()?.start_date)],
      end_date: [this.formatDateForInput(this.quizDates()?.end_date)],
      enrollment_end_date: [{
        value: formattedEnrollmentDate,
        disabled: !hasEnrollmentEndDate
      }],
      time_zone: [this.quizDates()?.time_zone ?? null, Validators.required]
    }, { validators: this.enrollmentEndDateValidation });

    const endDateSub = this.form.get('end_date')?.valueChanges.subscribe(value => {
      const enrollmentEndDateControl = this.form.get('enrollment_end_date');
      if (value) {
        this.calculateEnrollmentEndDate(new Date(value));
      } else {
        // Clear and disable enrollment end date when end date is cleared
        enrollmentEndDateControl?.setValue(null);
        enrollmentEndDateControl?.disable();
        this.calculatedEnrollmentEndDate.set(null);
        this.enrollmentDateLoaded.set(false);
      }
    });

    if (endDateSub) {
      this.subscriptions.add(endDateSub);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  // Format date string to be used in input[type="datetime-local"]
  private formatDateForInput(dateString: string | undefined): string | null {
    if (!dateString) return null;
    try {
      const date = new Date(dateString);
      // Format to YYYY-MM-DDThh:mm
      const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
      return localDate.toISOString().slice(0, 16);
    } catch (e) {
      return null;
    }
  }

  private calculateEnrollmentEndDate(endDate: Date): void {
    this.calculatingEndDate.set(true);
    this.enrollmentDateLoaded.set(false);

    const enrollmentEndDateControl = this.form.get('enrollment_end_date');
    if (enrollmentEndDateControl) {
      enrollmentEndDateControl.setErrors(null);
    }

    this.quiztimeApi.calculateEnrollmentEndDate(this.quizGuid, endDate).pipe(
      tap((calculatedDate: Date) => {
        this.calculatedEnrollmentEndDate.set(calculatedDate);

        if (enrollmentEndDateControl?.disabled) {
          enrollmentEndDateControl.enable();
        }

        enrollmentEndDateControl?.setValidators([Validators.required]);

        const formattedDate = this.formatDateForInput(calculatedDate.toISOString());
        enrollmentEndDateControl?.setValue(formattedDate);

        this.enrollmentDateLoaded.set(true);
        enrollmentEndDateControl?.updateValueAndValidity();
        this.form.updateValueAndValidity();
      }),
      catchError(err => {
        this.toast.error('Failed to calculate enrollment end date');
        throw err;
      }),
      finalize(() => {
        this.calculatingEndDate.set(false);
      })
    ).subscribe();
  }

  onTimeZoneSelected(timeZone: TimeZone | null) {
    this.form.patchValue({ time_zone: timeZone?.name });

    if (this.calculatedEnrollmentEndDate() && this.form.get('end_date')?.value) {
      this.calculateEnrollmentEndDate(new Date(this.form.get('end_date')?.value));
    }
  }

  enrollmentEndDateValidation = (group: AbstractControl) => {
    const start = group.get('start_date')?.value;
    const end = group.get('end_date')?.value;
    const enrollment = group.get('enrollment_end_date')?.value;
    const enrollmentEndDateControl = group.get('enrollment_end_date');

    if (this.calculatingEndDate()) {
      return null;
    }

    // If end date is empty, enrollment date validation isn't needed
    if (!end) {
      return null;
    }

    if (end && !enrollment) {
      enrollmentEndDateControl?.setErrors({ required: true });
      return { enrollmentEndDateRequired: true };
    }

    if (start && enrollment && new Date(enrollment) <= new Date(start)) {
      enrollmentEndDateControl?.setErrors({ invalidDate: true });
      return { enrollmentEndDateInvalid: true };
    }

    const calculatedDate = this.calculatedEnrollmentEndDate();
    if (calculatedDate && enrollment) {
      const enrollmentDate = new Date(enrollment);

      if (enrollmentDate > calculatedDate) {
        enrollmentEndDateControl?.setErrors({ laterThanCalculated: true });
        return { enrollmentEndDateTooLate: true };
      }
    }

    if (enrollmentEndDateControl?.errors) {
      enrollmentEndDateControl.setErrors(null);
    }

    return null;
  }

  onPublish(): void {
    this.isPublishing.set(true);
    // Does Canvas expect form values as UTC? If we are submitting form values, which
    // are in local time, do we need to convert them to UTC
    const updateDates = this.quiztimeApi.setQuizDates(this.quizGuid, this.form.value);
    const publish = this.quiztimeApi.publishQuiz(this.quizGuid);
    // Do updateDates and then publish in sequent
    updateDates.pipe(
      concatMap(() => publish),
      // TODO will also need to be updated with future card to handle error
      tap((publishedSuccessfully: boolean) => {
        if (publishedSuccessfully) {
          this.toast.success('Published successfully');
          return;
        }
        this.toast.error('Failed to publish');
      }),
      catchError(err => {
        this.toast.error('Failed to publish');
        throw err;
      }),
      finalize(() => {
        this.isPublishing.set(false);
        this.form.markAsPristine();
      })
    ).subscribe();
  }

  onSaveDraft(): void {
    this.isSavingDraft.set(true);
    this.quiztimeApi.setQuizDates(this.quizGuid, this.form.value).pipe(
      tap(() => this.toast.success('Saved as draft')),
      catchError(err => {
        this.toast.error('Failed to save as draft');
        throw err;
      }),
      finalize(() => {
        this.isSavingDraft.set(false);
        this.form.markAsPristine();
      })
    ).subscribe();
  }
}
