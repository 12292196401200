import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './layout/header/header.component';
import { TokenStorageService } from './services/token-storage/token-storage.service';
import { QuiztimeApiService } from './services/quiztime-api.service';
import { LtiUser } from './models/lti-user.interface';
import { catchError, of } from 'rxjs';
import { ToastContainerComponent } from './shared-components/toast-container/toast-container.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, ToastContainerComponent],
  styleUrl: './app.component.scss',
  template: `
    <main class="main">
      <div class="content">
        <app-header></app-header>
        <app-toast-container></app-toast-container>
        <router-outlet />
      </div>
    </main>
`
})
export class AppComponent {
  title = 'QuizTime : Manage your quizzes';
}
