<div class="container container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="alert alert-warning">
        <h2>Nothing to see here...</h2>
        <p>Maybe you want to <a [routerLink]="['/quizzes/new']"> create a quiz?</a></p>
      </div>
    </div>
  </div>
</div>
