import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthStore } from '../../../../store/auth.store';
import { AnnouncementsFormComponent } from '../../components/announcements-form/announcements-form.component';
import { AnnouncementsStateService } from '../../announcements-state.service';

@Component({
  selector: 'app-quiz-details-form-container',
  standalone: true,
  imports: [AnnouncementsFormComponent],
  template: `
    @if (user) {
      <app-announcements-form
        (formSubmitted)="handleSubmit($event)"
        [error]="stateService.error()"
        [isSubmitting]="stateService.workingWithApi()"
        [initialAnnouncements]="stateService.announcements()"
        [isLoading]="stateService.loadingAnnouncements()"
      ></app-announcements-form>
    }
  `
})
export class AnnouncementsFormContainerComponent implements OnInit, OnDestroy {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private authStore = inject(AuthStore);
  protected stateService = inject(AnnouncementsStateService);

  user = this.authStore.user();
  quizGuid!: string;

  ngOnInit(): void {
    if (!this.user) {
      throw new Error('User is not authenticated');
    }

    this.quizGuid = this.route.snapshot.paramMap.get('quizGuid') || '';
    this.stateService.initiateAnnouncementsState(this.quizGuid).subscribe();
  }

  ngOnDestroy(): void {
    this.stateService.resetState();
  }

  handleSubmit(event: { form: any }): void {
    if (!(event.form.start_of_quiz_announcement || event.form.end_of_quiz_announcement)) {
      this.router.navigate(['quizzes', this.quizGuid, 'questions']);
      return;
    }

    this.stateService.updateAnnouncements(this.quizGuid, event.form)
      .subscribe(() => this.router.navigate(['quizzes', this.quizGuid, 'questions']));
  }
}
