import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loading-button',
  standalone: true,
  imports: [CommonModule],
  styleUrl: './loading-button.component.scss',
  template: `
    <button
      [disabled]="disabled || loading"
      [type]="type"
      [class]="getButtonClasses()"
      [attr.data-testid]="testId"
      (click)="handleClick($event)">
        @if(loading) {
          <span class="spinner-border spinner-border-sm me-1"></span> Please wait
        }
        @else if(showSuccess) {
          <i class="bi bi-check-circle me-1"></i> {{ successMessage }}
        }
        @else if(showError) {
          <i class="bi bi-x-circle me-1"></i> {{ errorMessage }}
        }
        @else {
          <ng-content></ng-content>
        }
    </button>
  `
})
export class LoadingButtonComponent {
  @Input() testId: string = 'loading-button';
  @Input() disabled: boolean = true;
  @Input() loading: boolean = false;
  @Input() showSuccess: boolean = false;
  @Input() showError: boolean = false;
  @Input() successMessage: string = 'Success';
  @Input() errorMessage: string = 'Error';
  @Input() variant: 'primary' | 'success' | 'danger' = 'primary';
  @Input() type: 'button' | 'submit' | 'reset' = 'button';

  @Output() buttonClick = new EventEmitter<MouseEvent>();

  // Automatically determine variant or use explicitly specified one
  getButtonClasses(): string {
    let baseClasses = 'btn';

    // Determine button variant based on status
    if (this.showSuccess) {
      return `${baseClasses} btn-success`;
    } else if (this.showError) {
      return `${baseClasses} btn-danger`;
    } else {
      return `${baseClasses} btn-${this.variant}`;
    }
  }

  /**
   * To support [type]=button we have to intercept the
   * click and only emit if the button is not disabled
   */
  handleClick(event: MouseEvent): void {
    // Only prevent default for non-submit buttons
    if (this.type !== 'submit') {
      event.preventDefault();
    }

    // Always emit the click event if not disabled/loading
    if (!this.disabled && !this.loading) {
      this.buttonClick.emit(event);
    }
  }
}
