import { Component, Input, Output, EventEmitter, inject } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faEye, faPencil } from '@fortawesome/free-solid-svg-icons';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { QuizQuestionsStateService } from '../../../quiz-questions-state.service';
import { Question } from '../../../../../models/question.interface';

@Component({
  selector: 'app-question-header',
  standalone: true,
  imports: [FontAwesomeModule, NgbTooltip],
  template: `
    <!-- question-header.component.ts -->
    <div class="question-header d-flex w-100 align-items-top py-2 card-body border-0">
      <h5 class="card-title fs-6 p-0 m-0 text-nowrap">{{ question.name }}</h5>
      <div class="fw-light ms-3 px-3" [innerHTML]="!isOpen ? question.text : ''"></div>
      <div class="ms-auto d-flex align-items-start justify-content-end" style="min-width: 100px">
        @if (isOpen) {
          @if (question.provider_data?.id) {
            <button
              (click)="alert('Preview implemented in future story')"
              type="button"
              class="btn btn-link"><fa-icon class="text-primary" [icon]="previewIcon"></fa-icon> Preview</button>
          }
          @if (question.provider_data?.id) {
            <button
              (click)="toggle.emit()"
              type="button"
              class="btn btn-outline-primary">Cancel</button>
          }
          @else {
            <button
              (click)="removeQuestion(question)"
              type="button"
              class="btn btn-outline-primary">Cancel</button>
          }
        }
        @else {
          @if (question.provider_data?.id) {
            <button
              (click)="alert('Preview implemented in future story')"
              ngbTooltip="Preview"
              class="btn"
              type="button"
            >
              <fa-icon class="text-gray" [icon]="previewIcon"></fa-icon>
            </button>
          }
          <button
            (click)="toggle.emit()"
            ngbTooltip="Edit Question"
            class="btn ms-auto"
            type="button"
          >
            <fa-icon class="text-gray" [icon]="editIcon"></fa-icon><span class="visually-hidden">Edit Question</span>
          </button>
        }
      </div>
    </div>
  `
})
export class QuestionHeaderComponent {
  @Input() question!: Question;
  @Input() isOpen: boolean = false;
  @Output() collapse = new EventEmitter<void>();
  @Output() toggle = new EventEmitter<void>();

  private stateService = inject(QuizQuestionsStateService);

  editIcon = faPencil;
  previewIcon = faEye;

  removeQuestion(question: Question) {
    this.stateService.removeQuestion(question);
  }

  alert(message: string) {
    alert(message);
  }
}
