import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PrimaryNavComponent } from '../primary-nav/primary-nav.component';

@Component({
  selector: 'app-header',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [PrimaryNavComponent],
  // templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  template: `
    <header class="header container-lg">
      <app-primary-nav></app-primary-nav>
    </header>
  `
})
export class HeaderComponent {}
