<div class="container container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <h1>Developer Debugger</h1>
    </div>
    <hr>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <h2>Add Access Token</h2>
      <p>You can get an access token via Bruno and add add it to the applicaiton here:</p>
      <form [formGroup]="accessTokenForm" (ngSubmit)="submitAccessToken()">
        <div class="form-group">
          <label for="access_token">Access Token</label>
          <input formControlName="access_token" type="text" class="form-control" id="accessToken" />
        </div>
        <div class="form-group">
          <label for="expires_in">Expires In</label>
          <input formControlName="expires_in" class="form-control" type="number" id="expires_in" >
        </div>
        <button type="submit" class="btn btn-primary mt-4">Submit</button>
        @if (token)
        {
          <div class="alert alert-success mt-4" role="alert">
            There is a token set.
          </div>
        }
      </form>
    </div>
    <div class="col-sm-12 col-md-6">
      <h2>User Info</h2>
      @if (authStore.isLoading())
      {
        <div class="alert alert-info" role="alert">
          Loading...
        </div>
      }
      @else if (_user)
      {
        <div class="alert alert-success" role="alert">
          User is logged in:
          <pre>
            {{ _user | json }}
          </pre>
        </div>
      }
    </div>
  </div>
</div>
