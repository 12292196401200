import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, combineLatest, of, tap } from 'rxjs';
import { Token, TokenStorageService } from '../../../services/token-storage/token-storage.service';
import { LOCAL_STORAGE_REDIRECT_URL } from '../../../constants';

@Component({
  selector: 'app-entry',
  standalone: true,
  imports: [],
  template: ``,
})
export class EntryComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private tokenService = inject(TokenStorageService)

  ngOnInit(): void {
    const params$ = combineLatest([this.route.queryParamMap, this.route.fragment]);
    params$.pipe(
      tap(([params, fragment]) => {
        this.tokenService.setToken({access_token: fragment} as Token);
        let action;
        action = params.get('action');

        if (action) {
          /**
           * This needs a service that will map actions to routes.
           * We'll probably need to use the `data` property on routes combined
           * with some constants to accomplish this.
           *
           * The idea is that the angular app is responsible for setting redirects
           * as we NEVER want to redirect to a url or path provided by another app.
           */
          if (action === 'createQuiz') {
            localStorage.setItem(LOCAL_STORAGE_REDIRECT_URL, '/quizzes/new');
            return;
          }
          if (action === 'editQuiz') {
            console.log('params', params);
            localStorage.setItem(LOCAL_STORAGE_REDIRECT_URL, `/quizzes/${params.get('quiz')}`);
            return;
          }
        }
      }),
      catchError(err => {
        console.error('Error setting token', err);
        return of([])
      })
    ).subscribe(() => this.router.navigate(['/login']));
  }
}
