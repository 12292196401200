import { Component, inject, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-deactivate-modal',
  standalone: true,
  imports: [],
  template: `
    <div class="modal-header">
      <h4 class="modal-title">{{ heading }}</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close('stay')"></button>
    </div>
    <div class="modal-body">
      <p>{{ message }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('leave')">Continue without saving</button>
      <button type="button" class="btn btn-primary" (click)="activeModal.close('save')">Save</button>
    </div>
  `
})
export class ConfirmDeactivateModalComponent {
  @Input() heading!: string;
  @Input() message!: string;
  public modalService = inject(NgbModal);

  constructor(public activeModal: NgbActiveModal) {}
}
