import { QuizDatesRequest } from './../models/quizzes/quiz-dates-request.interface';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { Quiz } from '../models/quiz.interface';
import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from '../models/user.interface';
import { Token } from './token-storage/token-storage.service';
import { Enrollment } from '../models/enrollment.interface';
import { EnrollmentsRequest } from '../models/enrollments-request.interface';
import { InvitedEnrollment } from '../models/invited-enrollment.interface';
import { MediaResponse } from '../models/media-response.interface';
import { Question } from '../models/question.interface';
import { DeliveryConfiguration } from '../models/quizzes/delivery-configuration.interface';
import { EnrollmentState } from '../models/enrollment-state.enum';
import { Role } from '../models/role.enum';
import { BatchOperationResult } from '../models/batch-operation-result.interface';
import { Announcements } from '../quizzes/announcements/announcements-state.service';

@Injectable({
  providedIn: 'root'
})
export class QuiztimeApiService {
  private _http = inject(HttpClient);

  private getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'X-QuizTimeApi': 'true'
    });
  }

  createQuiz(quiz: Quiz) : Observable<string> {
    return this._http
      .post<string>(
        `${environment.quiztimeApiUrl}/api/v2/quizzes`,
        quiz,
        { headers: this.getHeaders() }
      )
      .pipe(
        catchError(err => {
          console.error('IMPORTANT: We need to integrate application insights in the ErrorInterceptor.');
          throw err;
        }
      ),
    );
  }

  updateQuiz(quiz: Quiz) : Observable<string> {
    return this._http
      .put<string>(
        `${environment.quiztimeApiUrl}/api/v2/quizzes/${quiz.quiz_guid}`,
        quiz,
        { headers: this.getHeaders() }
      )
      .pipe(
        catchError(err => {
          throw err;
        }
      ),
    );
  }

  getQuiz(quizGuid: string) : Observable<Quiz> {
    return this._http
      .get<Quiz>(
        `${environment.quiztimeApiUrl}/api/v2/quizzes/${quizGuid}`,
        { headers: this.getHeaders() }
      )
      .pipe(
        catchError(err => {
          throw err;
        }
      ),
    );
  }

  createOrUpdateAnnouncements(quiz_guid: string, start_of_quiz_announcement: string, end_of_quiz_announcement: string): Observable<string> {
    const body = { start_of_quiz_announcement, end_of_quiz_announcement };
    return this._http
      .post<string>(
        `${environment.quiztimeApiUrl}/api/v2/quizzes/${quiz_guid}/announcements`,
        body,
        { headers: this.getHeaders().set('Content-Type', 'application/json') }
      )
      .pipe(
        catchError(err => {
          throw err;
        })
      );
  }

  getAnnouncements(quizGuid: string): Observable<Announcements> {
    return this._http.get<Announcements>(`${environment.quiztimeApiUrl}/api/v2/quizzes/${quizGuid}/announcements`,
      { headers: this.getHeaders() }
    );
  }

  uploadMedia(quizGuid: string, media: File, isPrimaryImage = false) : Observable<MediaResponse> {
    const formData = new FormData();
    formData.append('media', media, media.name);
    return this._http
      .post<MediaResponse>(
        `${environment.quiztimeApiUrl}/api/v2/quizzes/${quizGuid}/media?isPrimaryImage=${isPrimaryImage}`,
        formData,
        { headers: this.getHeaders() }
      )
      .pipe(
        catchError(err => {
          throw err;
        }
      ),
    );
  }

  getUserInfo() : Observable<User> {
    return this._http
      .get<User>(
        `${environment.quiztimeApiUrl}/api/v2/connect/userinfo`,
        { headers: this.getHeaders() }
      )
      .pipe(
        catchError(err => {
          throw err;
        }
      ),
    );
  }

  getRefreshToken(email: string) : Observable<Token> {
    return this._http
      .post<Token>(
        `${environment.catalogUrl}/api/authorization/renew`,
        null,
        {
          withCredentials: true,
          headers: {
            'X-QuizTime-Admin-User': email
          }
        }
      )
      .pipe(
        catchError(err => {
          throw err;
        })
      );
  }

  getAdministrators(quizGuid: string) : Observable<Enrollment[]> {
    return this._http
      .get<Enrollment[]>(
        `${environment.quiztimeApiUrl}/api/v2/quizzes/${quizGuid}/enrollments?states=active&roles=admin`,
        { headers: this.getHeaders() }
      )
      .pipe(
        catchError(err => {
          throw err;
        }
      ),
    );
  }

  addEnrollments(quizGuid: string, enrollmentData: EnrollmentsRequest) : Observable<BatchOperationResult<Enrollment>> {
    return this._http
      .post<BatchOperationResult<Enrollment>>(
        `${environment.quiztimeApiUrl}/api/v2/quizzes/${quizGuid}/enrollments`,
        enrollmentData,
        { headers: this.getHeaders() }
      )
      .pipe(
        catchError(err => {
          throw err;
        }
      ),
    );
  }

  inviteLearners(quizGuid: string, emails: string[]) : Observable<InvitedEnrollment[]> {
    const body = { emails: emails };
    return this._http
      .post<InvitedEnrollment[]>(
        `${environment.quiztimeApiUrl}/api/v2/invited-enrollments/${quizGuid}/invite`,
        body,
        { headers: this.getHeaders().set('Content-Type', 'application/json') }
      )
      .pipe(
        catchError(err => {
          throw err;
        })
      );
  }

  getQuizDeliveryConfiguration(quizGuid: string) : Observable<DeliveryConfiguration> {
    return this._http
      .get<DeliveryConfiguration>(
        `${environment.quiztimeApiUrl}/api/v2/quizzes/${quizGuid}/delivery-configuration`,
        { headers: this.getHeaders() }
      )
      .pipe(
        catchError(err => {
          throw err;
        }
      ),
    );
  }

  putQuizDeliveryConfiguration(quizGuid: string, deliveryConfiguration: DeliveryConfiguration) : Observable<DeliveryConfiguration> {
    return this._http
      .post<DeliveryConfiguration>(
        `${environment.quiztimeApiUrl}/api/v2/quizzes/${quizGuid}/delivery-configuration`,
        deliveryConfiguration,
        { headers: this.getHeaders() }
      )
      .pipe(
        catchError(err => {
          throw err;
        }
      ),
    );
  }

  getQuizQuestions(quizGuid: string, includePreviewLinks: boolean = false) : Observable<Question[]> {
    return this._http
      .get<Question[]>(
        `${environment.quiztimeApiUrl}/api/v2/quizzes/${quizGuid}/questions${includePreviewLinks ? '?includePreviewLinks=true' : ''}`,
        { headers: this.getHeaders() }
      )
      .pipe(
        catchError(err => {
          throw err;
        }
      ),
    );
  }

  addOrUpdateQuestion(quizGuid: string, question: Question) : Observable<Question> {
    return this._http
      .post<Question>(
        `${environment.quiztimeApiUrl}/api/v2/quizzes/${quizGuid}/questions`,
        question,
        { headers: this.getHeaders() }
      )
      .pipe(
        catchError(err => {
          throw err;
        }
      ),
    );
  }

  getEnrollments(quizGuid: string, states: EnrollmentState[], roles: Role[]): Observable<Enrollment[]> {
    let params = new HttpParams();
    params = this.addParams(params, 'states', states.map(state => state.toString()));
    params = this.addParams(params, 'roles', roles.map(role => role.toString()));

    return this._http
      .get<Enrollment[]>(
        `${environment.quiztimeApiUrl}/api/v2/quizzes/${quizGuid}/enrollments`,
        { headers: this.getHeaders(), params: params }
      )
      .pipe(
        map(response => response.map(learner => ({
          ...learner,
          enrollment_date: new Date(learner.enrollment_date)
        }))),
        catchError(err => {
          throw err;
        })
      );
  }

  deleteEnrollment(quizGuid: string, email: string, role: Role) : Observable<boolean> {
    return this._http
      .delete<boolean>(
        `${environment.quiztimeApiUrl}/api/v2/quizzes/${quizGuid}/enrollments`,
        {
          headers: this.getHeaders(),
          body: { email: email, role: role }
        }
      )
      .pipe(
        catchError(err => {
          throw err;
        })
      );
  }

  setQuizDates(quizGuid: string, request: QuizDatesRequest) : Observable<boolean> {
    return this._http
      .post<boolean>(
        `${environment.quiztimeApiUrl}/api/v2/quizzes/${quizGuid}/dates`,
        request,
        { headers: this.getHeaders() }
      )
      .pipe(
        catchError(err => {
          throw err;
        })
      );
  }

  getQuizDates(quizGuid: string) : Observable<QuizDatesRequest> {
    return this._http
      .get<QuizDatesRequest>(
        `${environment.quiztimeApiUrl}/api/v2/quizzes/${quizGuid}/dates`,
        { headers: this.getHeaders() }
      )
      .pipe(
        catchError(err => {
          throw err;
        })
      );
  }

  calculateEnrollmentEndDate(quizGuid: string, quizEndDate: Date): Observable<Date> {
    const params = new HttpParams().set('quizEndDate', quizEndDate.toISOString());

    return this._http
      .get<string>(
        `${environment.quiztimeApiUrl}/api/v2/quizzes/${quizGuid}/calculate-enrollment-end-date`,
        { headers: this.getHeaders(), params }
      )
      .pipe(
        map(dateStr => new Date(dateStr)),
        catchError(err => {
          throw err;
        })
      );
  }

  // Todo: future card deals with an error on this, so update return type to be more specific
  publishQuiz(quizGuid: string) : Observable<boolean> {
    return this._http
      .put<boolean>(
        `${environment.quiztimeApiUrl}/api/v2/quizzes/${quizGuid}/publish`,
        null,
        { headers: this.getHeaders() }
      )
      .pipe(
        catchError(err => {
          throw err;
        })
      );
  }

  private addParams = (params: HttpParams, key: string, values: string[]) => {
    if (values && values.length > 0) {
      values.forEach(value => {
        params = params.append(key, value);
      });
    }

    return params;
  };
}
