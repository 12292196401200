import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-answer-item',
  standalone: true,
  imports: [ReactiveFormsModule, FontAwesomeModule],
  template: `
    <div [formGroup]="formGroup" class="d-flex mb-3">
      <div class="form-group flex-grow-1">
        <label class="visually-hidden" [for]="index + '-value'">{{ formGroup.value.provider_data?.id }}</label>
        <input data-testid="question-answer-item" [id]="index + '-value'" formControlName="text" type="text" class="form-control" />
      </div>
      <div class="ms-2">
        <input class="form-check-input me-2" type="checkbox" formControlName="is_correct" [id]="index + '--is-correct'">
        <ng-content select="label"></ng-content>
      </div>
      <div class="ms-2">
        <button type="button" class="btn btn-sm btn-link" (click)="this.deleteAnswer.emit(index)">
            <fa-icon class="text-danger" [icon]="trashIcon"></fa-icon>
          </button>
      </div>
    </div>
  `
})
export class AnswerItemComponent {
  @Input() formGroup!: FormGroup;
  @Input() index!: number;

  @Output() deleteAnswer = new EventEmitter<number>();

  trashIcon = faTrashCan;
}
