import { ChangeDetectionStrategy, Component, computed, inject, Input, input, signal, EventEmitter, Output } from '@angular/core';
import { QuestionComponent } from "../question/question.component";
import { QuizQuestionsStateService } from '../../quiz-questions-state.service';
import { NgxSkeletonLoaderComponent } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-question-list',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [QuestionComponent, NgxSkeletonLoaderComponent],
  template: `
    <div class="container">
      <div class="row">
        <p class="border-bottom fs-5">Questions</p>
      </div>
    </div>
    @if (questionCount() === 0 && !loadingQuestions() && !loadingQuestionsError()) {
      <div class="container">
        <div class="alert alert-info">
          No questions have been added yet.
        </div>
      </div>
    }
    <div [class.border-bottom]="questionCount() > 0" class="container p-0 question-list border-start border-end border-dark">
      @for (question of questions(); track $index) {
        <app-question
          [question]="question"
          [index]="$index"
          [startOpen]="isQuestionOpen($index)"
        ></app-question>
      }
    </div>
    @if (loadingQuestionsError() !== false) {
      <div class="container">
        <div class="alert alert-danger">
          {{ loadingQuestionsError() }}
        </div>
      </div>
    }
    @if (loadingQuestions()) {
      <div class="container">
        <div class="row">
          <ngx-skeleton-loader count="5" [theme]="{height: '60px'}" appearance="line" />
        </div>
      </div>
    }
    @if (!loadingQuestions()) {
      <div class="container">
        <div class="form-group mt-2">
          <button
            type="button"
            class="btn btn-outline-primary"
            [disabled]="hasUnsavedQuestion()"
            (click)="addQuestion()"
            data-testid="add-question-button">
            + Add Question
          </button>
          @if (hasUnsavedQuestion()) {
            <p class="ms-2 text-danger">Save or cancel the current question to add a new one</p>
          }
        </div>
      </div>
    }
  `
})
export class QuestionListComponent {
  private stateService = inject(QuizQuestionsStateService);

  loadingQuestions = computed(() => this.stateService.loadingQuestions());
  loadingQuestionsError = computed(() => this.stateService.loadingQuestionsError());
  hasUnsavedQuestion = computed(() => this.stateService.hasUnsavedQuestion());
  questionCount = computed(() => this.stateService.questions().length);
  questions = computed(() => this.stateService.questions());
  openQuestions = computed(() => this.stateService.openQuestions());

  addQuestion() {
    this.stateService.openQuestions.set([]);
    this.stateService.addNewQuestion();
  }

  isQuestionOpen(index: number): boolean {
    return this.openQuestions().includes(index);
  }
}
