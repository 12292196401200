import { Route, Routes } from '@angular/router';
import { QuizDetailsFormContainerComponent } from './quizzes/quiz-details/containers/quiz-details-form-container/quiz-details-form-container.component';
import { environment } from '../environments/environment';
import { QuizPublishComponent } from './quizzes/quiz-publish/quiz-publish.component';
import { DeveloperDebuggerComponent } from './debug/developer-debugger/developer-debugger.component';
import { primaryAuthGuard } from './guards/primary-auth.guard';
import { HomeComponent } from './home/home/home.component';
import { LoginRequiredComponent } from './home/components/login-required/login-required.component';
import { EntryComponent } from './home/components/entry/entry.component';
import { requiresFragmentGuard } from './guards/requires-fragment.guard';
import { AnnouncementsFormContainerComponent } from './quizzes/announcements/containers/announcements-container/announcements-container.component';
import { FormDirtyGuard } from './guards/form-dirty.guard';
import { QuizLearnersContainerComponent } from './quizzes/quiz-learners/containers/quiz-learners-container.component';
import { QuizQuestionsConfigurationContainerComponent } from './quizzes/quiz-questions/containers/quiz-questions-configuration-container/quiz-questions-configuration-container.component';
import { QuizWrapperComponent } from './layout/quiz-wrapper/quiz-wrapper.component';

const devRoutes = [];
if (!environment.production) {
  devRoutes.push({ path: 'debug', component: DeveloperDebuggerComponent, pathMatch: 'full' } as Route);
}

export const routes: Routes = [
  ...devRoutes,
  { path: 'quizzes', component: QuizWrapperComponent, children:
    [
      { path: 'new', component: QuizDetailsFormContainerComponent, canActivate: [primaryAuthGuard], canDeactivate: [FormDirtyGuard] },
      { path: ':quizGuid', component: QuizDetailsFormContainerComponent, canActivate: [primaryAuthGuard], canDeactivate: [FormDirtyGuard] },
      { path: ':quizGuid/announcements', component: AnnouncementsFormContainerComponent, canActivate: [primaryAuthGuard] },
      { path: ':quizGuid/learners', component: QuizLearnersContainerComponent, canActivate: [primaryAuthGuard] },
      { path: ':quizGuid/questions', component: QuizQuestionsConfigurationContainerComponent, canActivate: [primaryAuthGuard] },
      { path: ':quizGuid/publication', component: QuizPublishComponent, canActivate: [primaryAuthGuard] },
    ]
  },
  { path: 'entry', pathMatch: 'full', component: EntryComponent, canActivate: [requiresFragmentGuard] },
  { path: 'login', component: LoginRequiredComponent },
  { path: '', pathMatch: 'full', component: HomeComponent, canActivate: [primaryAuthGuard] },
];
