import { Component, Input, computed, signal, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

interface FormError {
  control: string;
  nestedIndex?: number;
  nestedControl?: string;
  errors: any;
  id: string; // Added for tracking
}

@Component({
  selector: 'app-form-debugger',
  standalone: true,
  imports: [CommonModule, NgbModule],
  template: `
    <div class="card mb-3 border-info">
      <div class="card-header bg-info text-white">
        <strong>Form Debugger</strong>
        <div class="float-end">
          <span class="badge rounded-pill me-2" [ngClass]="formStatus() === 'VALID' ? 'bg-success' : 'bg-danger'">
            {{ formStatus() }}
          </span>
          <button class="btn btn-sm btn-link text-white" (click)="toggleDisplay()">
            {{ showDebugger() ? 'Hide' : 'Show' }}
          </button>
        </div>
      </div>
      @if (showDebugger()) {
        <div class="card-body">
          <div class="alert alert-info mb-3">
            <h5>Form Values</h5>
            <pre>{{ formValues() }}</pre>
          </div>

          @if (formErrors().length) {
            <div class="alert alert-danger">
              <h5>Form Errors</h5>
              <ul class="list-group">
                @for (error of formErrors(); track error.id) {
                  <li class="list-group-item list-group-item-danger">
                    @if (error.nestedIndex !== undefined) {
                      <strong>{{ error.control }}[{{ error.nestedIndex }}].{{ error.nestedControl }}:</strong>
                    } @else {
                      <strong>{{ error.control }}:</strong>
                    }
                    <pre>{{ stringifyErrors(error.errors) }}</pre>
                  </li>
                }
              </ul>
            </div>
          }

          @if (form.errors) {
            <div class="alert alert-danger">
              <h5>Form-Level Errors</h5>
              <pre>{{ stringifyErrors(form.errors) }}</pre>
            </div>
          }
        </div>
      }
    </div>
  `,
})
export class FormDebuggerComponent implements OnInit, OnDestroy {
  @Input({ required: true }) form!: FormGroup;

  private _display = signal<boolean>(true);
  private _errors = signal<FormError[]>([]);
  private _values = signal<string>('No form provided');
  private _status = signal<string>('INVALID');
  private formSubscription?: Subscription;

  showDebugger = computed(() => this._display());
  formErrors = computed(() => this._errors());
  formValues = computed(() => this._values());
  formStatus = computed(() => this._status());

  ngOnInit(): void {
    if (this.form) {
      // Subscribe to value and status changes
      this.formSubscription = this.form.valueChanges.subscribe(() => {
        this.updateFormValues();
      });

      this.formSubscription.add(
        this.form.statusChanges.subscribe(() => {
          this.updateFormErrors();
          this._status.set(this.form.status);
        })
      );

      // Initialize with current values
      this.updateFormValues();
      this.updateFormErrors();
      this._status.set(this.form.status);
    }
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  markFormGroupTouched(formGroup: FormGroup): void {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }

      if (control instanceof FormArray) {
        control.controls.forEach(arrayControl => {
          if (arrayControl instanceof FormGroup) {
            this.markFormGroupTouched(arrayControl);
          } else {
            arrayControl.markAsTouched();
          }
        });
      }
    });
  }

  updateFormValues(): void {
    if (!this.form) {
      this._values.set('No form provided');
      return;
    }
    this._values.set(JSON.stringify(this.form.value, null, 2));
    this.updateFormErrors();
  }

  updateFormErrors(): void {
    if (!this.form) {
      this._errors.set([]);
      return;
    }

    const errors: FormError[] = [];

    // Check top-level form controls
    Object.keys(this.form.controls).forEach(key => {
      const control = this.form.get(key);

      if (!control) return;

      // Only include errors for touched controls to match Angular's behavior
      if (control.errors && (control.touched || control.dirty)) {
        errors.push({
          control: key,
          errors: control.errors,
          id: `${key}` // Simple unique ID for tracking
        });
      }

      // Check if this is a FormArray that needs further inspection
      if (control instanceof FormArray) {
        control.controls.forEach((arrayControl, index) => {
          if (arrayControl instanceof FormGroup) {
            // Process each control in the form group within the array
            Object.keys(arrayControl.controls).forEach(nestedKey => {
              const nestedControl = arrayControl.get(nestedKey);

              if (!nestedControl) return;

              // Only include errors for touched controls
              if (nestedControl.errors && (nestedControl.touched || nestedControl.dirty)) {
                errors.push({
                  control: key,
                  nestedIndex: index,
                  nestedControl: nestedKey,
                  errors: nestedControl.errors,
                  id: `${key}[${index}].${nestedKey}` // Composite ID for nested controls
                });
              }
            });
          } else if (arrayControl.errors && (arrayControl.touched || arrayControl.dirty)) {
            errors.push({
              control: key,
              nestedIndex: index,
              errors: arrayControl.errors,
              id: `${key}[${index}]` // Composite ID for array items
            });
          }
        });
      }
    });

    this._errors.set(errors);
  }

  toggleDisplay(): void {
    this._display.update(current => !current);
  }

  stringifyErrors(errors: any): string {
    return JSON.stringify(errors, null, 2);
  }
}
