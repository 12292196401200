import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userTimezone',
  standalone: true
})
export class UserTimezonePipe implements PipeTransform {
  transform(date: Date, timezone?: string, includeTime: boolean = true): { localTime: string; tzAbbr: string } {
    const options: Intl.DateTimeFormatOptions = {
      timeZone: timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
      hour12: true,
      month: 'numeric',
      day: 'numeric',
      year: '2-digit',
      ...(includeTime && { hour: 'numeric', minute: 'numeric' })
    };

    const formattedDate = date.toLocaleString('en-US', options);

    let tzAbbr = '';
    try {
      tzAbbr = new Intl.DateTimeFormat('en-US', {
        timeZone: options.timeZone,
        timeZoneName: 'short'
      }).formatToParts(date)
        .find(part => part.type === 'timeZoneName')?.value || '';
    } catch (e) {}

    return { localTime: formattedDate, tzAbbr };
  }
}
