import { inject } from '@angular/core';
import { ActivatedRoute, CanActivateFn, Router } from '@angular/router';

export const requiresFragmentGuard: CanActivateFn = (route, state) => {
  console.log('requiresFragmentGuard', route, state);
  const router = inject(Router);
  if (!route.fragment) {
    router.navigate(['/login']);
    return false;
  }

  return true;
};
