import { Component, Input, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-signal-debug',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="alert alert-light border p-2 mt-2" role="alert">
      <strong>{{ label }}:</strong> {{ value() | json }}
    </div>
  `,
  styles: [`
    div {
      font-family: monospace;
      font-size: 0.875rem;
    }
  `]
})
export class SignalDebugComponent {
  @Input() label: string = 'Signal';
  @Input({ required: true }) value!: Signal<any>;
}
