import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ReactiveFormsModule, FormBuilder, FormsModule, Validators, FormGroup } from '@angular/forms';
import { QuizAdministratorsListComponent } from '../quiz-administrators-list/quiz-administrators-list.component';
import { WysiwygEditorComponent } from '../../../../shared-components/wysiwyg-editor/wysiwyg-editor.component';
import { FileUploadComponent } from '../../../../shared-components/file-upload/file-upload.component';
import { RouterModule } from '@angular/router';
import { QuizAdministratorView } from '../../../../models/views/quiz-administrator-view.interface';
import { Quiz } from '../../../../models/quiz.interface';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { QuizErrorComponent } from '../../../../shared-components/quiz-error.component';
import { User } from '../../../../models/user.interface';
import { AddQuizAdministratorComponent } from '../add-quiz-administrator/add-quiz-administrator.component';
import { QuizDetailsFormLoaderComponent } from './quiz-details-form-loader.component';
import { LoadingButtonComponent } from '../../../../shared-components/loading-button/loading-button.component';
import { FormNavigationConfirmationDirective } from '../../../../directives/form-navigation-confirmation/form-navigation-confirmation.directive';

@Component({
  selector: 'app-quiz-details-form',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    RouterModule,
    FontAwesomeModule,
    QuizAdministratorsListComponent,
    WysiwygEditorComponent,
    FileUploadComponent,
    FormsModule,
    AddQuizAdministratorComponent,
    LoadingButtonComponent,
    QuizErrorComponent,
    QuizDetailsFormLoaderComponent,
    FormNavigationConfirmationDirective
  ],
  template: `
    <form (ngSubmit)="handleSubmit()" [formGroup]="quizDetailsForm" appFormNavigationConfirmation [protectedForm]="quizDetailsForm">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h2 class="mb-4">Quiz Details</h2>
          </div>
        </div>
        @if(loadingQuiz && !error) {
          <app-quiz-details-form-loader></app-quiz-details-form-loader>
        }
        @else {
          @if(error) {
            <div class="row">
              <div className="col-md-12">
                <app-quiz-error [error]="error"></app-quiz-error>
              </div>
            </div>
          }
            <div class="row">
              <div class="col-md-12">
                <input type="hidden" formControlName="lti_user_id" />
                <input type="hidden" formControlName="lms_plugin_instance_id" />
                <label for="name" class="form-label">
                  @if (name?.invalid && (name?.dirty || name?.touched)) {
                    <span class="text-danger">Name is required</span>
                  } @else {
                    * Name:
                      @if (isEditMode && !editFields.name.isEditing && canEdit) {
                        <button type="button" (click)="editFields.name.isEditing = true" class="btn text-primary px-0 pt-0 fs-6">
                          <fa-icon [icon]="editIcon"></fa-icon>
                        </button>
                    }
                  }
                </label>
                @if (isEditMode && !editFields.name.isEditing) {
                  <div class="d-flex flex-row">
                    <p class="form-control-plaintext pt-0">{{ name?.value }}</p>
                  </div>
                }
                @else {
                  <input
                    formControlName="name"
                    id="name"
                    class="form-control"
                    required
                    data-testid="name" />
                }
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-4">
                <app-quiz-administrators-list
                  [administrators]="administrators"
                  [pendingAdministrators]="pendingAdminEnrollments"
                  [owner]="owner"
                  (administratorRemoved)="this.administratorRemoved.emit($event)"
                  data-testid="quiz-details-form__quiz-administrators-list"
                  >
                </app-quiz-administrators-list>
                <app-add-quiz-administrator
                  (administratorsAdded)="addAdministrators($event)"
                  [isSubmitting]="isSubmittingAdmins"
                ></app-add-quiz-administrator>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-4">
                <label for="quiz-description" class="d-block">
                  Description: @if (isEditMode && canEdit && !editFields.description.isEditing) {
                    <button type="button" (click)="editFields.description.isEditing = true" class="btn text-primary px-0 pt-0 fs-6">
                      <fa-icon [icon]="editIcon"></fa-icon>
                    </button>
                  }
                </label>
                @if (isEditMode && !editFields.description.isEditing) {
                  <div class="d-flex flex-row">
                    <p class="form-control-plaintext pt-0" [innerHTML]="quiz?.description"></p>
                  </div>
                }
                @else {
                  <app-wysiwyg-editor
                    [parentForm]="quizDetailsForm"
                    [formControlName]="'description'"
                    [id]="'quiz-description'"
                    [placeholder]="'Add your Quiz description...'"
                    (imageUploadRequested)="handleWysiwygImageUpload($event)"
                  ></app-wysiwyg-editor>
                }
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-4">
                <p class="mb-0">Banner:</p>
                <app-file-upload
                  (fileAdded)="addFile($event)"
                  (fileRemoved)="removeFile($event)"
                  [parentForm]="quizDetailsForm"
                  [formControlName]="'image'"
                  [editImage]="quiz?.image_url"
                  [isEditMode]="isEditMode"
                ></app-file-upload>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-4 d-flex flex-row justify-content-end">
                <app-loading-button
                  [type]="'submit'"
                  [disabled]="quizDetailsForm.invalid || (isEditMode && (!canEdit || !quizDetailsForm.dirty))"
                  [loading]="isSubmitting"
                  [testId]="'quiz-details__submit-button'"
                >Save & Continue</app-loading-button>
              </div>
              @if (error != '') {
                <div class="col-md-12 mt-0 d-flex flex-row justify-content-end">
                  <p class="text-danger text-sm">{{ error }}</p>
                </div>
              }
            </div>
        }
      </div>
    </form>
  `
})
export class QuizDetailsFormComponent implements OnChanges {
  @Input() administrators: QuizAdministratorView[] = [];
  @Input() owner!: QuizAdministratorView;
  @Input() error!: string;
  @Input() isSubmitting!: boolean;
  @Input() isSubmittingAdmins = false;
  @Input() pendingAdminEnrollments: QuizAdministratorView[] = [];
  @Input() quiz!: Quiz | null;
  @Input() isEditMode = false;
  @Input() canEdit!: boolean;
  @Input() loadingQuiz!: boolean;
  @Input() user!: User;
  @Input() quizGuid!: string;

  @Output() formSubmitted = new EventEmitter<any>();
  @Output() adminstratorsAdded = new EventEmitter<any>();
  @Output() administratorRemoved = new EventEmitter<string>();
  @Output() imageUploadRequested = new EventEmitter<{ file: File; range: any; callback: (url: string) => void }>();

  editIcon = faPencil;

  private formBuilder = inject(FormBuilder);
  private bannerImage: File | null = null;
  private imageDirty = false;

  public editFields = {
    "name": {'isEditing': false},
    "description": {'isEditing': false},
    "image": {'isEditing': false}
  }
  public quizDetailsForm: FormGroup = this.formBuilder.group({});

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loadingQuiz && !this.loadingQuiz && !this.error) {
      const newQuizGuid = this.quiz ? this.quiz.quiz_guid : crypto.randomUUID().toString();
      this.quizGuid = newQuizGuid || crypto.randomUUID().toString();

      this.quizDetailsForm = this.formBuilder.group({
        quiz_guid: [this.quizGuid],
        lti_user_id: [this.quiz ? this.quiz.lti_user_id : this.user.ltiUser.id],
        lms_plugin_instance_id: [this.quiz ? this.quiz.lms_plugin_instance_id : 1],
        name: [this.quiz ? this.quiz.name : '', Validators.required],
        description: [this.quiz && this.quiz.description ? this.quiz.description : ''],
        image: [this.quiz && this.quiz.image_url ? this.quiz.image_url : ''],
      });
    }
  }

  get name() {
    return this.quizDetailsForm.get('name');
  }

  handleSubmit() {
    this.formSubmitted.emit({form: this.quizDetailsForm.value, image: { localFile: this.bannerImage, dirty: this.imageDirty }});
  }

  addFile(file: File) {
    this.bannerImage = file;
    this.imageDirty = true;
  }

  removeFile(file: File) {
    this.bannerImage = null;
    this.imageDirty = true;
  }

  addAdministrators(emails: string[]) {
    this.adminstratorsAdded.emit(emails);
  }

  removeAdministrator(email: string) {
    this.administrators = this.administrators.filter(admin => admin.email !== email);
  }

  handleWysiwygImageUpload(event: { file: File; range: any; callback: (url: string) => void }) {
    this.imageUploadRequested.emit(event);
  }
}
