import { Component, inject, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { RouterOutlet, RouterLink, RouterLinkActive, Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { filter, Subscription } from 'rxjs';
import { NgbOffcanvas, NgbOffcanvasModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faXmark, faCaretRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-quiz-wrapper',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    CommonModule,
    NgbOffcanvasModule,
    FontAwesomeModule
  ],
  styleUrls: ['./quiz-wrapper.component.scss'],
  template: `
    <div class="container position-relative">
      <!-- Toggle button for small screens -->
      <button
        class="btn btn-light menu-button d-md-none"
        aria-label="Open navigation menu"
        (click)="openNav(content)">
        <span class="custom-icon">
          <span class="line"></span>
          <span class="line"></span>
          <fa-icon [icon]="caretIcon" class="caret"></fa-icon>
        </span>
      </button>

      <div class="row mb-4">
        <!-- Sidebar for medium+ screens -->
        <div class="col-md-3 d-none d-md-block">
          <ng-container [ngTemplateOutlet]="navigationList"></ng-container>
        </div>

        <!-- Main content area -->
        <div class="col-12 col-md-9 content-area">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>

    <!-- Navigation list template -->
    <ng-template #navigationList>
      <ul class="nav flex-column">
        <li class="nav-item">
          <a
            class="nav-link text-black"
            [routerLink]="['/quizzes', quizGuid]"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}">Quiz Details</a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link text-black"
            [routerLink]="['/quizzes', quizGuid, 'announcements']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}"
            [class.disabled]="isNewQuiz"
            [attr.tabindex]="isNewQuiz ? '-1' : null"
            [attr.aria-disabled]="isNewQuiz ? 'true' : null">Announcements</a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link text-black"
            [routerLink]="['/quizzes', quizGuid, 'questions']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}"
            [class.disabled]="isNewQuiz"
            [attr.tabindex]="isNewQuiz ? '-1' : null"
            [attr.aria-disabled]="isNewQuiz ? 'true' : null">Questions</a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link text-black"
            [routerLink]="['/quizzes', quizGuid, 'learners']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}"
            [class.disabled]="isNewQuiz"
            [attr.tabindex]="isNewQuiz ? '-1' : null"
            [attr.aria-disabled]="isNewQuiz ? 'true' : null">Learners</a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="['/quizzes', quizGuid, 'reporting']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}"
            [class.disabled]="true"
            [attr.tabindex]="isNewQuiz ? '-1' : null"
            [attr.aria-disabled]="true">Reporting (pending work)</a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link text-black"
            [routerLink]="['/quizzes', quizGuid, 'publication']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}"
            [class.disabled]="false"
            [attr.tabindex]="isNewQuiz ? '-1' : null"
            [attr.aria-disabled]="isNewQuiz ? 'true' : null">Publication</a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="['/quizzes', quizGuid, 'guide']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}"
            [class.disabled]="true"
            [attr.tabindex]="isNewQuiz ? '-1' : null"
            [attr.aria-disabled]="isNewQuiz ? 'true' : null">Reference Guide (pending work)</a>
        </li>
      </ul>
    </ng-template>

    <!-- Offcanvas template -->
    <ng-template #content let-offcanvas>
      <div class="offcanvas-header">
        <h4 class="offcanvas-title">Quiz Navigation</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss()">
        </button>
      </div>
      <div class="offcanvas-body">
        <ng-container [ngTemplateOutlet]="navigationList"></ng-container>
      </div>
    </ng-template>
  `
})
export class QuizWrapperComponent implements OnInit, OnDestroy {
  quizGuid: string = '';
  isNewQuiz: boolean = false;
  private routerSubscription: Subscription | null = null;

  // FontAwesome icons
  closeIcon = faXmark;
  caretIcon = faCaretRight;

  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private offcanvasService = inject(NgbOffcanvas);

  ngOnInit(): void {
    // Get the current quizGuid from the route params
    this.route.firstChild?.paramMap.subscribe(params => {
      const guid = params.get('quizGuid');
      if (guid) {
        this.quizGuid = guid;
        this.isNewQuiz = guid === 'new';
        console.log('QuizGuid from params:', this.quizGuid);
      }
    });

    // Subscribe to router events to update when navigation occurs
    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.checkCurrentRoute(event.url);
      // Close offcanvas when navigation occurs
      this.offcanvasService.dismiss();
    });

    // Initial check on component init
    this.checkCurrentRoute(this.router.url);
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  // Open the offcanvas navigation
  openNav(content: TemplateRef<any>): void {
    this.offcanvasService.open(content, { position: 'start' });
  }

  private checkCurrentRoute(url: string): void {
    const urlParts = url.split('/');

    // Check if we're in a "new" quiz route
    if (urlParts.includes('new')) {
      this.isNewQuiz = true;
      this.quizGuid = 'new';
    } else {
      // Find the index of "quizzes" in the URL
      const quizzesIndex = urlParts.findIndex(part => part === 'quizzes');
      if (quizzesIndex !== -1 && quizzesIndex + 1 < urlParts.length) {
        // The quiz GUID should be the part after "quizzes"
        const guid = urlParts[quizzesIndex + 1];
        if (guid && guid !== this.quizGuid) {
          this.quizGuid = guid;
          this.isNewQuiz = guid === 'new';
          console.log('QuizGuid from URL:', this.quizGuid);
        }
      }
    }
  }
}
