import { Component, EventEmitter, Input, OnInit, Output, signal, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { getTimeZones, TimeZone } from '@vvo/tzdb';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'app-timezone-selector',
  standalone: true,
  imports: [CommonModule, FormsModule, NgSelectModule],
  encapsulation: ViewEncapsulation.None,
  template: `
    <div class="timezone-selector" [class.disabled]="disabled">
      <ng-select
        [items]="filteredTimeZones()"
        [bindLabel]="'name'"
        [placeholder]="placeholder"
        [clearable]="false"
        [searchable]="true"
        [disabled]="disabled"
        [(ngModel)]="selectedTimeZone"
        (change)="onTimeZoneChange($event)"
        [groupBy]="groupByRegion"
        [groupValue]="groupValueFn"
        [selectableGroup]="false"
        appendTo="body"
        [searchFn]="customSearchFn"
      >
        <!-- Custom header template with icon -->
        <ng-template ng-label-tmp let-item="item">
          <span class="d-flex align-items-center">
            {{ item?.name }}
          </span>
        </ng-template>

        <!-- Option template -->
        <ng-template ng-option-tmp let-item="item">
          <div class="timezone-option">
            <strong>{{ item.name }}</strong> <small class="text-muted">({{ item.countryName }})</small>
            <small class="text-muted d-block">
              {{ item.group.join(', ') }}
            </small>
          </div>
        </ng-template>

        <!-- Group header template -->
        <ng-template ng-optgroup-tmp let-item="item">
          {{ item }}
        </ng-template>
      </ng-select>
    </div>
  `
})
export class TimezoneSelectorComponent implements OnInit {
  @Input() placeholder: string = 'Select a timezone';
  @Input() defaultTimeZone: string | null = null;
  @Input() disabled: boolean = false;
  @Input() prioritizeUSTimezones: boolean = true;

  @Output() timeZoneSelected = new EventEmitter<TimeZone | null>();

  // Common US timezones
  private commonUSTimezones = [
    'America/New_York',
    'America/Chicago',
    'America/Denver',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'Pacific/Honolulu',
  ];

  private readonly US_GROUP = 'Common US Timezones';

  timeZones = signal<TimeZone[]>([]);
  filteredTimeZones = signal<TimeZone[]>([]);
  selectedTimeZone: TimeZone | null = null;
  userLocalTimeZone: string | null = null;

  ngOnInit() {
    // Load timezone data
    const allTimeZones = getTimeZones({ includeUtc: true })
      .sort((a, b) => a.name.localeCompare(b.name));
    this.timeZones.set(allTimeZones);

    // Set initial timezone: defaultTimeZone > user's timezone > null
    this.userLocalTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const initialZone = this.defaultTimeZone || this.userLocalTimeZone;
    this.selectedTimeZone = this.timeZones().find(tz => tz.name === initialZone) || null;

    // Initialize filtered timezones
    this.updateFilteredTimeZones();

    // Emit initial selection
    if (this.selectedTimeZone) {
      this.timeZoneSelected.emit(this.selectedTimeZone);
    }
  }

  // Group by region, prioritizing US timezones
  groupByRegion = (item: TimeZone): string => {
    // Check if it's a common US timezone
    if (this.prioritizeUSTimezones && this.commonUSTimezones.includes(item.name)) {
      return this.US_GROUP;
    }

    // For other timezones, use the first part of their name as group
    const group = item.name.split('/')[0];
    return group;
  };

  // The label for the goup function, really just using for US Timezones benefit
  groupValueFn = (key: string, children: TimeZone[]): string => {
    return key;
  };

  // Custom search function that includes mainCities
  customSearchFn = (term: string, item: TimeZone): boolean => {
    if (!term) {
      return true;
    }

    term = term.toLowerCase();

    // Search in name
    if (item.name.toLowerCase().includes(term)) {
      return true;
    }

    // Search in countryName
    if (item.countryName.toLowerCase().includes(term)) {
      return true;
    }

    // Search in mainCities
    if (item.mainCities && item.mainCities.some(city => city.toLowerCase().includes(term))) {
      return true;
    }

    // Search in group
    if (item.group && item.group.some(group => group.toLowerCase().includes(term))) {
      return true;
    }

    // Search in abbreviation
    if (item.abbreviation && item.abbreviation.toLowerCase().includes(term)) {
      return true;
    }

    return false;
  };

  // Update filtered timezones with prioritized common regions and US timezones
  updateFilteredTimeZones(): void {
    // First get common US timezones
    const usTimezones = this.prioritizeUSTimezones
      ? this.timeZones().filter(tz => this.commonUSTimezones.includes(tz.name))
        // Sort by the order in the commonUSTimezones array
        .sort((a, b) =>
          this.commonUSTimezones.indexOf(a.name) - this.commonUSTimezones.indexOf(b.name)
        )
      : [];

    // Get all other timezones
    const otherTimezones = this.timeZones().filter(tz =>
      !this.commonUSTimezones.includes(tz.name)
    );

    // Combine all timezones with US ones first
    this.filteredTimeZones.set([
      ...usTimezones,
      ...otherTimezones
    ]);
  }

  // Handle timezone selection change
  onTimeZoneChange(timeZone: TimeZone | null): void {
    this.timeZoneSelected.emit(timeZone);
  }
}
