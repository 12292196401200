import { Component, inject, OnInit, signal, Signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuiztimeApiService } from '../../../services/quiztime-api.service';
import { AuthStore } from '../../../store/auth.store';
import { AddQuizLearnersComponent } from '../components/add-quiz-learners/add-quiz-learners.component';
import { catchError, finalize, tap } from 'rxjs';
import { EnrollmentState } from '../../../models/enrollment-state.enum';
import { Role } from '../../../models/role.enum';
import { Enrollment } from '../../../models/enrollment.interface';
import { EnrollmentsTableComponent } from '../components/enrollments-table/enrollments-table.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'app-quiz-learners-container',
  standalone: true,
  imports: [AddQuizLearnersComponent, EnrollmentsTableComponent, NgxSkeletonLoaderModule],
  template: `
    <div class="container">
      @if (user) {
        <h1>Learners</h1>
        <app-add-quiz-learners-input
          (formSubmitted)="handleSubmit($event)"
          [error]="error"
          [isSubmitting]="isSubmitting"
        ></app-add-quiz-learners-input>
        @if (this.loadingEnrollments()) {
          <h2 class="mt-5 h4 mb-0 pb-2 border-bottom d-flex align-items-center justify-content-between">Enrolled Learners</h2>
          <ngx-skeleton-loader count="1" [theme]="{height: '100px'}" appearance="line" />
          <ngx-skeleton-loader count="3" [theme]="{height: '25px'}" appearance="line" />

          <h2 class="h4 mb-0 pb-2 border-bottom d-flex align-items-center justify-content-between">Unenrolled Learners</h2>
          <ngx-skeleton-loader count="1" [theme]="{height: '100px'}" appearance="line" />
          <ngx-skeleton-loader count="3" [theme]="{height: '25px'}" appearance="line" />
        }
        @if (this.enrollmentsLoaded()) {
          <div class="mt-5">
            <div class="mb-5">
              <app-enrollments-table
                data-testid="enrollments-table"
                [learners]="this.enrolledLearners"
                title="Enrolled Learners"
                [autoExpandWhenNotEmpty]="false"
                (enrollmentRemoved)="handleUnenrollRequest($event)"
                [deletingLearnerId]="deletingLearnerId()"
              ></app-enrollments-table>
            </div>
            <app-enrollments-table
              data-testid="unenrollments-table"
              [learners]="this.unenrolledLearners"
              title="Unenrolled Learners"
              [isCollapsed]="true"
              [autoExpandWhenNotEmpty]="true"
            ></app-enrollments-table>
          </div>
        }
        <div class="d-flex justify-content-end mt-4">
          <!-- <app-loading-button
            [loading]="isSubmitting"
            [disabled]="isSubmitting"
            [testId]="'quiz-learners__save-continue'"
            (click)="handleSaveAndContinue()"
          >Save & Continue</app-loading-button> -->
        </div>
      }
    </div>
  `
})
export class QuizLearnersContainerComponent implements OnInit {
  private route: ActivatedRoute = inject(ActivatedRoute);
  private quiztimeApi: QuiztimeApiService = inject(QuiztimeApiService);
  private authStore: AuthStore = inject(AuthStore);
  private toastService = inject(ToastService);

  user = this.authStore.user();
  error!: string;
  isSubmitting: boolean = false;
  quizGuid!: string;
  enrollments: Enrollment[] = [];
  loadingEnrollments = signal<boolean>(false);
  enrollmentsLoaded = signal<boolean>(false);
  deletingLearnerId = signal<string | null>(null);
  enrolledLearners: Enrollment[] = [];
  unenrolledLearners: Enrollment[] = [];

  ngOnInit(): void {
    this.loadingEnrollments.set(true);
    if (!this.user) {
      throw new Error('User is not authenticated');
    }

    this.quizGuid = this.route.snapshot.paramMap.get('quizGuid') ?? (() => { throw new Error('Quiz unknown') })();
    this.loadLearners();
  }

  private loadLearners(): void {
    this.quiztimeApi.getEnrollments(
        this.quizGuid,
        [EnrollmentState.Active, EnrollmentState.Invited, EnrollmentState.Inactive, EnrollmentState.Deleted],
        [Role.Student])
      .pipe(
        tap(() => this.loadingEnrollments),
        tap((enrollments: Enrollment[]) => {
          this.enrollments = enrollments;
          this.enrolledLearners = this.enrollments.filter(l =>
            l.state === EnrollmentState.Active
            || l.state === EnrollmentState.Completed
            || l.state === EnrollmentState.Invited
          );
          this.unenrolledLearners = this.enrollments.filter(l => l.state === EnrollmentState.Inactive || l.state === EnrollmentState.Deleted);
        }),
        catchError(err => {
          this.error = 'We encountered an error while loading learners. Please try again.';
          throw err;
        }),
        finalize(() => {
          this.loadingEnrollments.set(false);
          this.enrollmentsLoaded.set(true);
        })
      )
      .subscribe();
  }

  public handleSubmit(event: { form: any }) {
    this.isSubmitting = true;

    if (!(event.form.emailList)) {
      this.isSubmitting = false;
      return;
    }

    const emailArray = event.form.emailList.split(/,|\s+/).map((email: string) => email.trim());

    this.quiztimeApi.inviteLearners(this.quizGuid, emailArray)
    .pipe(
      catchError(err => {
        this.error = 'We encountered an error while adding learners. Please try again.';
        throw err;
      }),
      finalize(() => this.isSubmitting = false)
    )
    .subscribe();
  }

  public launchLearnerDetailReport(learner: Enrollment): void {
    alert('Will be implemented in EIQT-2266');
  }

  public handleUnenrollRequest(learner: Enrollment): void {
    this.deletingLearnerId.set(learner.user.email);

    this.quiztimeApi.deleteEnrollment(this.quizGuid, learner.user.email, learner.role)
      .pipe(
        tap(() => {
          this.enrolledLearners = this.enrolledLearners.filter(e => e.user.email !== learner.user.email);
          this.toastService.success(`Uenrolled ${learner.user.email}`);
        }),
        catchError(err => {
          this.toastService.error(`Failed to unenroll ${learner.user.email}`);
          throw err;
        }),
        finalize(() => {
          this.deletingLearnerId.set(null);
        })
      )
      .subscribe();
  }

  public handleSaveAndContinue(): void {
    alert('Will be implemented in EIQT-2262');
  }
}
