import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class TableService {
  constructor() {}
  promptTableSize(callback: (rows: number, cols: number) => void) {
    const input = prompt('Enter the number of rows and columns (e.g., "3,4" for 3 rows and 4 columns):', '2,2');
    if (input) {
      const [rows, cols] = input.split(',').map(Number);
      if (rows && cols) {
        callback(rows, cols);
      } else {
        alert('Invalid input. Please enter the number of rows and columns in the format "rows,columns".');
      }
    }
  }
  // There is a codepen that illustrates how to use Quill 2.0 to manipulate table rows but there's a lot of additional UI elements that would
  // need to be considered: https://codepen.io/quill/pen/QxypzX
  // This implementation can be a bit janky but it's servicable for now I think. There are issues when trying to delete the table or when
  // trying to backspace the table to the top of the editor. A space at the beginning at least lets us delete the table with select all +delete.
  insertTable(rows: number, cols: number, quillEditorRef: any) {
    const range = quillEditorRef.getSelection();
    if (range) {
      let tableHtml = '<div>&nbsp;<table border="1">';
      for (let i = 0; i < rows; i++) {
        tableHtml += '<tr>';
        for (let j = 0; j < cols; j++) {
          tableHtml += '<td>&nbsp;</td>';
        }
        tableHtml += '</tr>';
      }
      tableHtml += '</table>&nbsp;</div>';
      quillEditorRef.clipboard.dangerouslyPasteHTML(range.index, tableHtml);
    }
  }
}
