import { Component, EventEmitter, inject, Input, Output, TemplateRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-confirmation-modal',
  standalone: true,
  imports: [FontAwesomeModule],
  styleUrl: './confirmation-modal.component.scss',
  template: `
    <ng-template #content let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">
            <ng-content select="modal-header"></ng-content>
          </h4>
          <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
        </div>
        <div class="modal-body">
            <ng-content select="modal-body"></ng-content>
        </div>
        <div class="modal-footer justify-content-start">
          <button type="button" class="btn btn-outline-primary" (click)="modal.close()">
            <ng-content select="modal-negative"></ng-content>
          </button>
          <button type="button" class="btn btn-danger" (click)="affirmative(modal)">
            <ng-content select="modal-affirmative"></ng-content>
          </button>
        </div>
    </ng-template>
    <button [attr.data-testid]="triggerButtonTestId" type="button" (click)="open(content)" class="btn btn-{{ triggerButtonColor }}">
      @if (triggerButtonIcon !== undefined) {
        <fa-icon *ngIf="triggerButtonIcon" [icon]="triggerButtonIcon"></fa-icon>
      }
      <ng-content select="modal-trigger-inner"></ng-content>
    </button>
  `,
})
export class ConfirmationModalComponent {
  @Input() triggerButtonIcon?: any;
  @Input() triggerButtonColor: string = 'primary';
  @Input() triggerButtonTestId: string = 'confirmation-modal__trigger';
  @Output() isAffirmative = new EventEmitter<any>();
  private modalService = inject(NgbModal);
  faXmark = faXmark;

  open(content: TemplateRef<any>) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
	}

  affirmative(modal: NgbActiveModal) {
    this.isAffirmative.emit();
    modal.close();
  }
}
