import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTrashCan, faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

const FILE_UPLOAD_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FileUploadComponent),
  multi: true
}

@Component({
  selector: 'app-file-upload',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, FontAwesomeModule, ReactiveFormsModule],
  providers: [FILE_UPLOAD_CONTROL_ACCESSOR],
  styleUrl: './file-upload.component.scss',
  template: `
    <div [formGroup]="parentForm" class="file-upload-wrapper border border-dashed rounded p-4">
      @if(isEditMode && editImage) {
        <div class="image-container position-relative">
          <img width="600" height="300" [src]="editImage" alt="Current image" class="mb-2" />
          <button
            (click)="removeFile($event)"
            type="button"
            class="btn bg-white btn-outline-danger bg-white bg-hover-danger remove-button position-absolute top-0 start-0">
            <fa-icon [icon]="trashIcon"></fa-icon>  Remove Image
          </button>
        </div>
      }
      @else {
        @if(!selectedFile) {
          <div
            (drop)="drop($event)"
            (dragover)="dragOver($event)"
            class="file-dropzone">
            <label for="file-upload" class="file-dropzone-label">
              Drag your image here or
                <span class="btn btn-sm btn-outline-primary ms-1 d-inline-block">
                  Select a file
                  <fa-icon
                    [icon]="uploadIcon"
                    class="text-primary ms-1"
                    ></fa-icon>
                </span>
              <input
                (change)=addFile($event)
                [formControlName]="formControlName"
                [accept]="acceptedImageTypes.join()"
                id="file-upload"
                class="visually-hidden"
                type="file" />
            </label>
            <p class="fs-6 text-muted mb-0"><small>Supported file types: PNG, JPEG, JPG, GIF</small></p>
            <p class="fs-6 text-muted mt-0"><small>For optimal display, images should be 2:1 ratio with minimum dimensions of 600px wide by 300px tall.</small></p>
            @if(uploadError) {
              <p class="fs-6 text-danger">{{ uploadError }}</p>
            }
          </div>
        }
        @else {
          <div>
            <span class="d-inline-block px-4 py-2 border bg-light rounded">
              {{ selectedFile.name }}
            </span>
            <button (click)="removeFile($event)" type="button" class="btn">
              <fa-icon class="text-danger" [icon]="trashIcon"></fa-icon>
            </button>
          </div>
        }
      }
    </div>
  `
})
export class FileUploadComponent implements ControlValueAccessor {
  @Input() parentForm!: FormGroup;
  @Input() formControlName!: string;
  @Input() isEditMode: boolean = false;
  @Input() editImage: string | null | undefined = null;
  @Output() fileAdded = new EventEmitter<File>();
  @Output() fileRemoved = new EventEmitter<File>();

  acceptedImageTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
  uploadError: boolean|string = false;
  selectedFile: File | null = null;
  trashIcon = faTrashCan;
  uploadIcon = faArrowUpFromBracket;

  private onModelChange!: Function;

  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}

  addFile(event: Event) {
    this.uploadError = false;
    const target = event.target as HTMLInputElement;
    if (target.files) {
      this._setFile(target.files[0]);
    }
  }

  removeFile(event: Event) {
    const removedFile = this.selectedFile as File;
    this.selectedFile = null;
    this.editImage = null;
    this.onModelChange(null);
    this.fileRemoved.emit(removedFile);
  }

  drop(event: DragEvent) {
    this.uploadError = false;
    this._preventAndStop(event);
    if (event.dataTransfer?.files) {
      const files = event.dataTransfer.files;
      if (files.length > 1) {
        this.uploadError = 'Only one file can be uploaded at a time.';
        return;
      }
      if (!this.acceptedImageTypes.includes(files[0].type)) {
        this.uploadError = 'Invalid file type. Supported file types: PNG, JPEG, JPG, GIF';
        return;
      }
      this._setFile(files[0]);
    }
  }

  dragOver(event: DragEvent) {
    this._preventAndStop(event);
  }

  _preventAndStop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  _setFile(file: File) {
    this.selectedFile = file;
    this.onModelChange(this.selectedFile.name);
    this.fileAdded.emit(this.selectedFile);
  }

  _displayImage() {
    const img = document.createElement("img");
    img.classList.add("obj");
    document.getElementById('preview')?.appendChild(img); // Assuming that "preview" is the div output where the content will be displayed.

    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target?.result) {
        img.src = e.target.result as string;
      }
    };
    reader.readAsDataURL(this.selectedFile as Blob);
  }
}
