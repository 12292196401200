import { Component, computed, EventEmitter, inject, Input, OnInit, Output, signal } from '@angular/core';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { QuestionHeaderComponent } from './components/question-header.component';
import { QuestionFormComponent } from './components/question-form.component';
import { Question } from '../../../../models/question.interface';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faEye, faPencil } from '@fortawesome/free-solid-svg-icons';
import { QuizQuestionsStateService } from '../../quiz-questions-state.service';

@Component({
  selector: 'app-question',
  standalone: true,
  imports: [
    NgbAccordionModule,
    FontAwesomeModule,
    QuestionHeaderComponent,
    QuestionFormComponent
],
  template: `
    <div ngbAccordion #accordion="ngbAccordion">
      <div
        [collapsed]="!this.isOpen()"
        ngbAccordionItem="question-{{ index }}-accordion"
        class="card border-dark border-top-1 border-end-0 border-bottom-0 border-start-0 rounded-0">
        <div
          ngbAccordionHeader
          class="question-header d-flex w-100 align-items-top py-2 card-body border-0"
        >
          <app-question-header
            class="w-100"
            [question]="question"
            [isOpen]="isOpen()"
            (collapse)="collapse()"
            (toggle)="toggleAccordion(accordion)"
          ></app-question-header>
        </div>

        <div ngbAccordionCollapse attr.data-testid="question-{{ index }}-collapse">
          <div ngbAccordionBody>
            <ng-template>
              <app-question-form
                [question]="question"
                [index]="index"
              ></app-question-form>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  `
})
export class QuestionComponent {
  @Input() startOpen: boolean = false;
  @Input() question!: Question;
  @Input() index!: number;

  private stateService = inject(QuizQuestionsStateService);

  isOpen = computed(() => this.stateService.openQuestions().includes(this.index));
  editIcon = faPencil;
  previewIcon = faEye;

  collapse() {
    this.stateService.openQuestions.set(
      this.isOpen() ? this.stateService.openQuestions().filter(i => i !== this.index) : [...this.stateService.openQuestions(), this.index]
    );
  }

  toggleAccordion(accordion: any) {
    if (this.isOpen()) {
      this.stateService.closeQuestion(this.index);
      return;
    }
    this.stateService.openQuestion(this.index);
  }
}
