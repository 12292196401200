import { environment as defaultEnvironment } from './environment';

export const environment:any = {
  ...defaultEnvironment,
  production: false,
  catalogUrl: 'https://dev.quiztimehealth.com',
  quiztimeApiUrl: 'https://dev-api.quiztimehealth.com',
  logLevel: 'debug',
  featureFlags: {}
};
