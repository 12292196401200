import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function commaOrWhitespaceSeparatedValidator(validatorFn: ValidatorFn): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const values = control.value ? control.value.split(/,|\s+/) : [];

    const invalidValues = new Set();

    for (const value of values) {
      const trimmedValue = value.trim();
      if (trimmedValue && validatorFn({ value: trimmedValue } as AbstractControl)) {
        invalidValues.add(trimmedValue);
      }
    }

    if (invalidValues.size) {
      return { commaOrWhitespaceSeparatedErrors: Array.from(invalidValues) };
    }

    return null;
  };
}
