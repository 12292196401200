import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DatePipe, JsonPipe } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faDownload, faSort, faSortUp, faSortDown, faUserMinus, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faClock, faFileLines } from '@fortawesome/free-regular-svg-icons';
import { DeliveryTimePipe } from '../../../../pipes/delivery-time.pipe';
import { NgbTooltipModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { UserTimezonePipe } from '../../../../pipes/user-timezone.pipe';
import { Enrollment } from '../../../../models/enrollment.interface';
import { inject } from '@angular/core';
import { ExcelExportService } from '../../../../services/excel-export.service';
import { EnrollmentState } from '../../../../models/enrollment-state.enum';
import { ConfirmationModalComponent } from '../../../../shared-components/confirmation-modal/confirmation-modal.component';

type SortColumn = 'enrollment_date' | 'first_name' | 'email' | 'mode' | 'delivery_time';
type SortDirection = 'asc' | 'desc' | '';

interface SortState {
  column: SortColumn;
  direction: SortDirection;
}

@Component({
  selector: 'app-enrollments-table',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    DatePipe,
    FontAwesomeModule,
    DeliveryTimePipe,
    NgbTooltipModule,
    NgbPaginationModule,
    FormsModule,
    UserTimezonePipe,
    ConfirmationModalComponent,
  ],
  styles: [`
    ::ng-deep ngb-pagination ul {
      margin-bottom: 0;
    }
    .collapse-pointer-icon {
      &.collapsed {
        transform: rotate(-90deg);
      }
      transition: transform 0.2s ease-in-out;
    }
  `],
  template: `
    <div class="mb-3">
      <button class="btn btn-link text-decoration-none p-0 w-100"
              (click)="handleToggleCollapse()"
              [attr.aria-expanded]="!isCollapsed">
        <h2 class="h4 mb-0 pb-2 border-bottom d-flex align-items-center justify-content-between">
          <span>{{ title }}</span>
          <fa-icon
            [icon]="faCaretDown"
            class="text-secondary ms-2 collapse-pointer-icon"
            [class.collapsed]="isCollapsed">
          </fa-icon>
        </h2>
      </button>
    </div>

    <div [class.d-none]="isCollapsed">
      <div class="mb-3 d-flex align-items-center gap-4">
        @if (showHideInvited) {
          <div class="form-check form-switch form-switch-lg">
            <input class="form-check-input border-secondary" type="checkbox" role="switch"
                   (change)="toggleInvitedUsers()"
                   id="hideInvitedToggle">
            <label class="form-check-label fw-medium" for="hideInvitedToggle">
              Hide Invited Users
            </label>
          </div>
        }
        <button
          class="btn text-black text-decoration-underline p-0 border-0"
          (click)="downloadTableData($event)"
          [disabled]="!learners.length"
          >
            <fa-icon [icon]="faDownload" class="me-2"></fa-icon>
            Download {{ title }}
        </button>
      </div>

      <div class="table-responsive">
        <table class="table">
          @if (learners.length) {
            <thead class="table-secondary">
              <tr>
                <th class="sortable col-enrollment" (click)="onSort('enrollment_date')">
                  Enrollment Date
                  <fa-icon [icon]="getSortIcon('enrollment_date')" class="ms-1"></fa-icon>
                </th>
                <th class="sortable col-name" (click)="onSort('first_name')">
                  Full Name
                  <fa-icon [icon]="getSortIcon('first_name')" class="ms-1"></fa-icon>
                </th>
                <th class="sortable col-email" (click)="onSort('email')">
                  Email Address
                  <fa-icon [icon]="getSortIcon('email')" class="ms-1"></fa-icon>
                </th>
                <th class="sortable col-delivery" (click)="onSort('mode')">
                  Delivery Method
                  <fa-icon [icon]="getSortIcon('mode')" class="ms-1"></fa-icon>
                </th>
                <th class="sortable col-time" (click)="onSort('delivery_time')">
                  Delivery Time
                  <fa-icon [icon]="getSortIcon('delivery_time')" class="ms-1"></fa-icon>
                </th>
                <th class="text-center col-actions">Actions</th>
              </tr>
            </thead>
          }

          <tbody>
            @for (learner of paginatedLearners; track learner.user.email) {
              <tr data-testid="enrollments-table-row">
                <td data-testid="enrollment-date">
                @if (learner.state === 'invited') {
                  <span>
                    <fa-icon [icon]="faClock" class="me-1 text-secondary"></fa-icon>
                    Invite Sent
                  </span>
                } @else {
                  @if (learner.profile.timezone) {
                    {{ (learner.enrollment_date | userTimezone:learner.profile.timezone:false).localTime }}
                  } @else {
                    {{ learner.enrollment_date | date:'shortDate' }}
                  }
                }
                </td>
                <td>
                  <a href="#" (click)="onReportClick($event, learner)" class="text-primary text-decoration-none">
                    {{ formatName(learner) }}
                  </a>
                </td>
                <td data-testid="enrollment-email">
                  <a href="mailto:{{ learner.user.email }}" class="text-primary text-decoration-none">
                    {{ learner.user.email }}
                  </a>
                </td>
                <td>{{ learner.delivery?.mode || 'N/A' }}</td>
                <td [ngbTooltip]="learner.delivery?.delivery_time| deliveryTime:'':'local'"
                    container="body">
                  {{ learner.delivery?.delivery_time | deliveryTime:learner.profile.timezone }}
                </td>
                <td class="text-center" data-testid="enrollment-actions">
                  <div class="d-flex gap-3 justify-content-center">
                    <button
                      [ngbTooltip]="'View Learner Details'"
                      class="btn btn-link p-0"
                      (click)="onReportClick($event, learner)" title="View Detail Report">
                        <fa-icon [icon]="faFileLines" class="text-dark"></fa-icon>
                    </button>
                    @if (showUnenroll(learner)) {
                      <app-confirmation-modal
                       [triggerButtonColor]="'transparent'"
                       [triggerButtonTestId]="'remove-learner-trigger'"
                       (isAffirmative)="unenrollLearner($event, learner)"
                       >
                        <modal-header>Remove user from Quiz?</modal-header>
                        <modal-body>
                          <p>This will remove the user from this quiz and they will not recieve any further questions.</p>
                        </modal-body>
                        <modal-affirmative>Remove access</modal-affirmative>
                        <modal-negative>Cancel</modal-negative>
                        <modal-trigger-inner>
                          @if (deletingLearnerId === learner.user.email) {
                            <div data-testid="learner-deletion-spinner" class="spinner-border spinner-border-sm text-danger" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          }
                          @else {
                            <fa-icon [ngbTooltip]="'Remove Learner'" [icon]="faUserMinus" class="text-danger"></fa-icon>
                          }
                        </modal-trigger-inner>
                      </app-confirmation-modal>
                    }
                    @if (deletingLearnerId === learner.user.email) {

                    }
                  </div>
                </td>
              </tr>
            } @empty {
              <tr>
                <td colspan="6" class="text-center">
                  <div class="alert alert-info">This Quiz does not have any {{ this.title }}.</div>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>

      @if (sortedLearners.length > 0) {
        <div class="d-flex justify-content-between align-items-center mt-3">
          <div class="d-flex align-items-center">
            <label class="me-2">Items per page:</label>
            <select class="form-select form-select-sm" style="width: auto;"
                    [(ngModel)]="pageSize" (change)="onPageSizeChange()"
                    [disabled]="sortedLearners.length <= getMinPageSize()">
              @for (size of pageSizeOptions; track size) {
                <option [ngValue]="size">{{ size }}</option>
              }
            </select>
          </div>

          @if (sortedLearners.length > pageSize) {
            <ngb-pagination
              [collectionSize]="sortedLearners.length"
              [(page)]="page"
              [pageSize]="pageSize"
              [maxSize]="5"
              [rotate]="true"
              [boundaryLinks]="true"
              (pageChange)="onPageChange($event)">
            </ngb-pagination>
          }
        </div>
      }
    </div>
  `,
})
export class EnrollmentsTableComponent implements OnInit, OnChanges {
  @Input() learners: Enrollment[] = [];
  @Input() title: string = '';
  @Input() isCollapsed = false;
  @Input() autoExpandWhenNotEmpty = false;
  @Input() defaultSortColumn: SortColumn = 'enrollment_date';
  @Input() pageSize = 10;
  @Input() deletingLearnerId: string | null = null;

  @Output() enrollmentRemoved = new EventEmitter<Enrollment>();

  page = 1;
  pageSizeOptions = [10, 25, 50];
  showHideInvited = false;
  invitedHidden = false;
  unFilteredLearners: Enrollment[] = [];

  // FontAwesome icons
  faDownload = faDownload;
  faSort = faSort;
  faSortUp = faSortUp;
  faSortDown = faSortDown;
  faUserMinus = faUserMinus;
  faFileLines = faFileLines;
  faClock = faClock;
  faCaretDown = faCaretDown;

  private excelExportService = inject(ExcelExportService);
  private sortState: SortState = { column: 'enrollment_date', direction: 'desc' };
  private userCollapsedPreference: boolean | null = null;

  ngOnInit(): void {
    this.sortState.column = this.defaultSortColumn;
    this.showHideInvited = this.learners.some(learner => learner.state === EnrollmentState.Invited);
    this.unFilteredLearners = this.learners;
    this.checkAutoExpand();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['learners'] && !changes['isCollapsed']) {
      this.checkAutoExpand();
    }
  }

  get sortedLearners(): Enrollment[] {
    return this.sortLearners(this.learners);
  }

  get paginatedLearners(): Enrollment[] {
    const startItem = (this.page - 1) * this.pageSize;
    const endItem = startItem + this.pageSize;
    return this.sortedLearners.slice(startItem, endItem);
  }

  private sortLearners(learners: Enrollment[]): Enrollment[] {
    if (!this.sortState.direction) return learners;

    return [...learners].sort((a, b) => {
      const direction = this.sortState.direction === 'asc' ? 1 : -1;

      switch (this.sortState.column) {
        case 'enrollment_date':
          return (a.enrollment_date.getTime() - b.enrollment_date.getTime()) * direction;
        case 'first_name':
          return this.compareNames(a, b) * direction;
        case 'email':
          return this.compareValues(a.user.email, b.user.email) * direction;
        case 'mode':
          return this.compareValues(a.delivery?.mode, b.delivery?.mode) * direction;
        case 'delivery_time':
          return this.compareValues(a.delivery?.delivery_time, b.delivery?.delivery_time) * direction;
        default:
          return 0;
      }
    });
  }

  onSort(column: SortColumn): void {
    if (this.sortState.column === column) {
      this.sortState.direction = this.sortState.direction === 'asc' ? 'desc' :
                                this.sortState.direction === 'desc' ? '' : 'asc';
    } else {
      this.sortState = { column, direction: 'asc' };
    }
    this.page = 1;
  }

  onPageChange(page: number): void {
    this.page = page;
  }

  onPageSizeChange(): void {
    // Reset to first page when changing page size to avoid empty pages
    this.page = 1;
  }

  toggleInvitedUsers(): void {
    if (!this.invitedHidden) {
      this.learners = this.learners.filter(learner => learner.state !== EnrollmentState.Invited);
      this.invitedHidden = true;
      return;
    }
    this.learners = this.unFilteredLearners;
    this.invitedHidden = false;
  }

  private checkAutoExpand(): void {
    if (!this.autoExpandWhenNotEmpty || this.userCollapsedPreference !== null) return;
  }

  handleToggleCollapse(): void {
    const newState = !this.isCollapsed;
    this.isCollapsed = newState;
    this.userCollapsedPreference = newState;
  }

  formatName(learner: Enrollment): string {
    if (!learner.profile.first_name && !learner.profile.last_name) return 'N/A';

    return [learner.profile.last_name, learner.profile.first_name].filter(Boolean).join(', ');
  }

  getSortIcon(column: SortColumn): any {
    if (this.sortState.column !== column) return this.faSort;
    if (this.sortState.direction === 'asc') return this.faSortUp;
    if (this.sortState.direction === 'desc') return this.faSortDown;
    return this.faSort;
  }

  onReportClick(event: Event, learner: Enrollment): void {
    event.preventDefault();
  }

  unenrollLearner(event: Event, learner: Enrollment): void {
    this.enrollmentRemoved.emit(learner);
  }

  downloadTableData(event: Event): void {
    this.downloadAsExcel();
  }

  showUnenroll(learner: Enrollment): boolean {
    return learner.state !== EnrollmentState.Completed
      && learner.state !== EnrollmentState.Deleted
      && learner.state !== EnrollmentState.Inactive;
  }

  /**
   * Prepares and downloads enrollment data as Excel file
   */
  private downloadAsExcel(): void {
    if (this.learners.length === 0) return;

    // Prepare data for export - convert complex objects to simple ones
    const exportData = this.sortedLearners.map(learner => {
      return {
        'Enrollment Date': this.formatEnrollmentDate(learner),
        'Full Name': this.formatName(learner),
        'Email Address': learner.user.email,
        'Delivery Method': learner.delivery?.mode || 'N/A',
        'Delivery Time': learner.delivery?.delivery_time || 'N/A',
        'Timezone': learner.profile.timezone,
        'State': learner.state || 'N/A'
      };
    });

    // Generate filename using the title and current date
    const date = new Date().toISOString().split('T')[0];
    const filename = `${this.title.replace(/\s+/g, '_')}_${date}`;

    this.excelExportService.exportToExcel(exportData, filename);
  }

  /**
   * Format enrollment date consistently for Excel export
   */
  private formatEnrollmentDate(learner: Enrollment): string {
    if (learner.state === 'invited') {
      return 'Invite Sent';
    }

    return learner.enrollment_date ?
      new Date(learner.enrollment_date).toLocaleDateString() :
      'N/A';
  }

  trackByEmail(index: number, learner: Enrollment): string {
    return learner.user.email;
  }

  private compareNames(a: Enrollment, b: Enrollment): number {
    const nameA = [a.profile.last_name, a.profile.first_name].filter(Boolean).join(', ');
    const nameB = [b.profile.last_name, b.profile.first_name].filter(Boolean).join(', ');
    return nameA.localeCompare(nameB);
  }

  private compareValues(a: string | undefined, b: string | undefined): number {
    if (a === b) return 0;
    if (a === undefined) return -1;
    if (b === undefined) return 1;
    return a.localeCompare(b);
  }

  getMinPageSize(): number {
    return Math.min(...this.pageSizeOptions);
  }
}
