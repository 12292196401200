import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthStore } from '../store/auth.store';
import { LOCAL_STORAGE_REDIRECT_URL } from '../constants';
import { TokenStorageService } from '../services/token-storage/token-storage.service';

export const primaryAuthGuard: CanActivateFn = (route, state) => {
  console.log('primaryAuthGuard', route, state);
  const router = inject(Router);
  const authStore = inject(AuthStore);
  const tokenStore = inject(TokenStorageService);

  const user = authStore.user();
  if (user && tokenStore.getToken() == null) {
    console.log('problem - user but no token')
  }
  console.log(authStore.user());

  console.log(tokenStore.getToken())

  if (authStore.user()) return true;

  localStorage.setItem(LOCAL_STORAGE_REDIRECT_URL, state.url);
  router.navigate(['/login']);

  return false;
};
