import { Component, inject, OnInit, signal, ViewChild } from '@angular/core';
import { QuizDetailsFormComponent } from "../../components/quiz-details-form/quiz-details-form.component";
import { QuiztimeApiService } from '../../../../services/quiztime-api.service';
import { catchError, concatMap, finalize, Observable, of, tap } from 'rxjs';
import { AuthStore } from '../../../../store/auth.store';
import { IFormDirtyGuard } from '../../../../guards/form-dirty.guard';
import { Role } from '../../../../models/role.enum';
import { EnrollmentState } from '../../../../models/enrollment-state.enum';
import { QuizAdministratorView } from '../../../../models/views/quiz-administrator-view.interface';
import { EnrollmentsRequest } from '../../../../models/enrollments-request.interface';
import { EnrollmentRequest } from '../../../../models/enrollment-request.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { Quiz } from '../../../../models/quiz.interface';
import { Enrollment } from '../../../../models/enrollment.interface';
import { ToastService } from '../../../../services/toast.service';

@Component({
  selector: 'app-quiz-details-form-container',
  standalone: true,
  imports: [QuizDetailsFormComponent],
  template: `
    <app-quiz-details-form
      #quizDetailsForm
      (formSubmitted)="handleSubmit($event)"
      (adminstratorsAdded)="addAdministrators($event)"
      (administratorRemoved)="removeAdministrator($event)"
      (imageUploadRequested)="handleImageUpload($event)"
      [administrators]="initialAdmins"
      [pendingAdminEnrollments]="pendingAdminEnrollments"
      [owner]="owner"
      [error]="error"
      [isSubmitting]="isSubmitting"
      [isSubmittingAdmins]="isSubmittingAdmins"
      [quiz]="quiz"
      [isEditMode]="isEditMode"
      [canEdit]="canEdit"
      [loadingQuiz]="loadingQuiz"
      [user]="user!"
    ></app-quiz-details-form>
  `
})
export class QuizDetailsFormContainerComponent implements OnInit, IFormDirtyGuard {
  private quiztimeApi: QuiztimeApiService = inject(QuiztimeApiService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private toastService = inject(ToastService);
  authStore: AuthStore = inject(AuthStore);
  owner: QuizAdministratorView = {} as QuizAdministratorView;
  initialAdmins: QuizAdministratorView[] = [];
  pendingAdminEnrollments: QuizAdministratorView[] = [];
  user = this.authStore.user();
  error!: string;
  isSubmitting: boolean = false;
  isSubmittingAdmins: boolean = false;
  isEditMode: boolean = false;
  canEdit: boolean = true;
  quiz: Quiz | null = null;
  loadingQuiz: boolean = true;

  @ViewChild('quizDetailsForm') quizDetailsFormComponent!: QuizDetailsFormComponent;

  ngOnInit() {
    const user = this.authStore.user();
    if (!user) {
      throw new Error('User is not authenticated');
    }

    this.route.paramMap.subscribe(params => {
      const quizGuid = params.get('quizGuid');
      if (quizGuid) {
        this.isEditMode = true;
        this.loadQuizData(quizGuid);
      }
      else {
        this.loadingQuiz = false;
        this.owner = {
          email: user.ltiUser.email,
          firstName: user.ltiUserProfile?.first_name ?? '',
          lastName: user.ltiUserProfile?.last_name ?? '',
          role: Role.Owner,
          status: EnrollmentState.Active
        };
        this.initialAdmins = [this.owner];
      }
    });
  }

  canDeactivate = () => !this.quizDetailsFormComponent.quizDetailsForm.dirty;

  saveConfirmed = () => this.quizDetailsFormComponent.handleSubmit();

  handleImageUpload(event: { file: File; range: any; callback: (url: string) => void }) {
    this.quiztimeApi.uploadMedia(this.quizDetailsFormComponent.quizGuid, event.file).subscribe({
      next: (response) => {
        event.callback(response.image_url);
      },
      error: () => {
        this.error = 'Failed to upload image';
      }
    });
  }

  handleSubmit(event: QuizRequestForm) {
    this.isSubmitting = true;
    let action;
    if (this.isEditMode) {
      action = this.updateQuizAction(event);
    }
    else {
      action = this.createQuizAction(event);
    }

    action
      .pipe(
        finalize(() => this.isSubmitting = false)
      )
      .subscribe(() => {
        this.quizDetailsFormComponent.quizDetailsForm.markAsPristine();
        this.router.navigate(['quizzes', this.quizDetailsFormComponent.quizGuid, 'announcements']);
      });
  }

  addAdministrators(event: string[]) {
    const enrollmentRequests: EnrollmentRequest[] = event.map(email => ({ email, role: Role.Admin }));
    const newEnrollments = enrollmentRequests.map(enrollment => ({ ...enrollment, status: EnrollmentState.Unknown, isWorkingAdding: true }));
    const allEnrollments = [...this.pendingAdminEnrollments, ...newEnrollments];
    this.pendingAdminEnrollments = allEnrollments.filter((enrollment, index, self) =>
      index === self.findIndex(e => e.email === enrollment.email)
    );

    // For new quizzes, don't make API calls - just collect administrators for later
    if (!this.isEditMode) {
      // Mark pending admins as no longer working since we're not making an API call
      this.pendingAdminEnrollments = this.pendingAdminEnrollments.map(admin => ({
        ...admin,
        isWorkingAdding: false
      }));
      return;
    }

    // Only make API calls for existing quizzes
    this.quiztimeApi
      .addEnrollments(this.quizDetailsFormComponent.quizGuid, { enrollments: enrollmentRequests })
      .pipe(
        tap((resp) => {
          if (resp.failed.length > 0) throw new Error('Failed to add administrators');
          this.toastService.success(`Added administrators: ${enrollmentRequests.map(enrollment => enrollment.email).join(', ')}`);
        }),
        concatMap(() => this.refreshAdmins(this.quizDetailsFormComponent.quizGuid)),
        catchError(err => {
          this.toastService.error(`Failed to add administrators: ${enrollmentRequests.map(enrollment => enrollment.email).join(', ')}`);
          return of(null);
        }),
        finalize(() => {
          const emailsToRemove = enrollmentRequests.map(er => er.email);
          this.pendingAdminEnrollments = this.pendingAdminEnrollments.filter(
            enrollment => !emailsToRemove.includes(enrollment.email)
          );
        })
      )
      .subscribe();
  }

  removeAdministrator(email: string) {
    alert('Completion of administrator removal has not been implemented yet');
    if (this.owner.email === email) {
      throw new Error('Cannot remove owner');
    }
    this.pendingAdminEnrollments = [...this.pendingAdminEnrollments.filter(admin => admin.email !== email)];
  }

  private refreshAdmins(quizGuid: string) : Observable<Enrollment[] | null> {
    return this.quiztimeApi.getAdministrators(quizGuid)
      .pipe(
        tap((admins: Enrollment[]) => {
          this.initialAdmins = admins.map(admin => ({
            email: admin.user.email,
            firstName: admin.profile.first_name,
            lastName: admin.profile.last_name,
            role: admin.role,
            status: admin.state
          }));
        }),
        catchError(err => {
          this.error = 'We encountered an error while loading the administrators. Please try again.';
          return of(null)
        })
      );
  }

  private loadQuizData(quizGuid: string) {
    this.quiztimeApi.getQuiz(quizGuid)
      .pipe(
        tap(quiz => this.quiz = quiz),
        concatMap(() => this.refreshAdmins(quizGuid)),
        catchError(err => {
          this.error = 'We encountered an error while loading the quiz. Please try again.';
          this.canEdit = false;
          return of(null)
        }),
        finalize(() => this.loadingQuiz = false)
      ).subscribe();
  }

  private updateQuizAction(event: QuizRequestForm) : Observable<any> {
    console.log('Updating quiz', event);
    return this.quiztimeApi.updateQuiz(event.form)
      .pipe(
        concatMap(() => {
          if (!event.image.dirty) return of(null);
          if (!event.image.localFile) return of(null);
          return this.quiztimeApi.uploadMedia(event.form.quiz_guid, event.image.localFile, true)
            .pipe(
              catchError(err => {
                throw err;
              })
            )
        }),
        tap((resp) => console.log('Media updated', resp)),
        catchError(err => {
          this.error = 'We encountered an error while updating the quiz. Please try again.';
          throw err;
        })
      )
  }

  private createQuizAction(event: QuizRequestForm) : Observable<any> {
    return this.quiztimeApi.createQuiz(event.form)
      .pipe(
        concatMap((createdGuid: string) => {
          if (event.image.localFile === null) return of(createdGuid);
          return this.quiztimeApi.uploadMedia(createdGuid, event.image.localFile, true)
            .pipe(
              catchError(err => {
                throw err;
              })
            )
        }),
        concatMap(() => {
          const owner = this.owner;
          const enrollmentsRequest: EnrollmentsRequest = {
            enrollments: [owner, ...this.pendingAdminEnrollments.map(admin => ({ email: admin.email, role: Role.Admin }))]
          };
          return this.quiztimeApi.addEnrollments(event.form.quiz_guid, enrollmentsRequest)
            .pipe(
              catchError(err => {
                this.error = 'We encountered an error while adding administrators. Please try again.';
                throw err;
              })
            );
        }),
        catchError(err => {
          this.error = 'We encountered an error while creating the quiz. Please try again.';
          throw err;
        }),
      );
  }
}

interface QuizRequestForm {
  form: any
  image: { localFile: File, dirty: boolean }
}
