import { WysiwygEditorComponent } from '../../../../shared-components/wysiwyg-editor/wysiwyg-editor.component';
import { Component, EventEmitter, inject, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ReactiveFormsModule, FormBuilder, Validators } from '@angular/forms';
import { LoadingButtonComponent } from "../../../../shared-components/loading-button/loading-button.component";
import { QuizErrorComponent } from "../../../../shared-components/quiz-error.component";
import { Announcements } from '../../announcements-state.service';
import { NgxSkeletonLoaderComponent } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-announcements-form',
  styleUrls: ['./announcements-form.component.css'],
  standalone: true,
  imports: [
    WysiwygEditorComponent,
    LoadingButtonComponent,
    QuizErrorComponent,
    ReactiveFormsModule,
    NgxSkeletonLoaderComponent
  ],
  template: `
    @if (isLoading) {
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h2 class="mb-4">Announcements</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label for="start-of-quiz-announcement" class="d-block mb-2">Start of Quiz Announcement:</label>
            <ngx-skeleton-loader [theme]="{ height: '200px' }" appearance="line"></ngx-skeleton-loader>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-12">
            <label for="end-of-quiz-announcement" class="d-block mb-2">End of Quiz Announcement:</label>
            <ngx-skeleton-loader [theme]="{ height: '200px' }" appearance="line"></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    } @else {
      <form (ngSubmit)="handleSubmit()" [formGroup]="announcementsForm">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h2 class="mb-4">Announcements</h2>
            </div>
          </div>
          @if(error) {
            <div class="row">
              <div className="col-md-12">
                <app-quiz-error></app-quiz-error>
              </div>
            </div>
          }
          <div class="row">
            <div class="col-md-12 mt-4">
              <app-wysiwyg-editor
                [parentForm]="announcementsForm"
                [formControlName]="'start_of_quiz_announcement'"
                [id]="'start-of-quiz-announcement'"
                [placeholder]="'Add your announcement text...'"
                >
                  <label for="start-of-quiz-announcement" class="d-block">Start of Quiz Announcement:</label>
              </app-wysiwyg-editor>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mt-4">
              <app-wysiwyg-editor
                [parentForm]="announcementsForm"
                [formControlName]="'end_of_quiz_announcement'"
                [id]="'end-of-quiz-announcement'"
                [placeholder]="'Add your announcement text...'"
                >
                  <label for="end-of-quiz-announcement" class="d-block">End of Quiz Announcement:</label>
              </app-wysiwyg-editor>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mt-4 d-flex flex-row justify-content-end">
              <app-loading-button
                [type]="'submit'"
                [disabled]="announcementsForm.invalid"
                [loading]="isSubmitting"
                [testId]="'announcements-form__submit-button'"
              >Save & Continue</app-loading-button>
            </div>
            @if (error != '') {
              <div class="col-md-12 mt-0 d-flex flex-row justify-content-end">
                <p class="text-danger text-sm">{{ error }}</p>
              </div>
            }
          </div>
        </div>
      </form>
    }
  `
})
export class AnnouncementsFormComponent implements OnInit, OnChanges {
  @Input() error!: string;
  @Input() isSubmitting!: boolean;
  @Input() isLoading!: boolean;
  @Input() initialAnnouncements?: Announcements;
  @Output() formSubmitted = new EventEmitter<{form: Partial<Announcements>}>();

  private formBuilder = inject(FormBuilder);

  announcementsForm = this.formBuilder.group({
    start_of_quiz_announcement: [''],
    end_of_quiz_announcement: ['']
  });

  private updateFormValues(): void {
    if (this.initialAnnouncements) {
      this.announcementsForm.patchValue({
        start_of_quiz_announcement: this.initialAnnouncements.start_of_quiz_announcement || '',
        end_of_quiz_announcement: this.initialAnnouncements.end_of_quiz_announcement || ''
      });
    }
  }

  ngOnInit(): void {
    this.updateFormValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['initialAnnouncements'] && !changes['initialAnnouncements'].firstChange) {
      this.updateFormValues();
    }
  }

  handleSubmit(): void {
    if (this.announcementsForm.valid) {
      const formValue = this.announcementsForm.value;
      this.formSubmitted.emit({
        form: {
          start_of_quiz_announcement: formValue.start_of_quiz_announcement || undefined,
          end_of_quiz_announcement: formValue.end_of_quiz_announcement || undefined
        }
      });
    }
  }
}
