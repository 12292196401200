import { Component, EventEmitter, inject, Input, Output, TemplateRef } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { commaSeparatedValidator } from '../../../../validators/comma-separated.validator';
import { LoadingButtonComponent } from "../../../../shared-components/loading-button/loading-button.component";

@Component({
  selector: 'app-add-quiz-administrator',
  standalone: true,
  imports: [ReactiveFormsModule, LoadingButtonComponent],
  styleUrl: './add-quiz-administrator.component.scss',
  template: `
    <ng-template #addAdminModal let-modal>
      <form (ngSubmit)="handleSubmit(modal)" [formGroup]="administratorForm">
        <div class="modal-header">
          <h4 class="modal-title" id="add-admin-title">
            Add Administrators
          </h4>
          <button type="button" class="btn-close" aria-label="Close" (click)="close(modal)"></button>
        </div>
        <div class="modal-body">
            <p class="fw-bold">To add administrators, enter their email address below.</p>
            <p>Only users with valid VUMC email addresses can be added at this time.</p>
            <label for="name" class="form-label">* Email:</label>
              <input
                placeholder="Enter VUMC.org email addresses separated by commas"
                formControlName="emailList"
                data-testid="add-quiz-administrator__email-input"
                type="text"
                class="form-control"  />
              @if (emailList?.errors?.commaSeparatedErrors) {
                <p class="mt-1 p-0 text-danger">The following emails are not not valid VUMC email addresses.  At this time, only users with valid &#64;vumc.org email addresses can be added.</p>
                <ul class="list-unstyled text-danger" data-testid="add-quiz-administrator__error-list">
                  @for (email of emailList?.errors?.commaSeparatedErrors; track $index;) {
                    <li>{{ email }}</li>
                  }
                </ul>
              }
        </div>
        <div class="modal-footer justify-content-start">
          <button type="button" class="btn btn-outline-primary" (click)="close(modal)" data-testid="add-quiz-administrator__cancel">
            Cancel
          </button>
          <app-loading-button
              [type]="'submit'"
              [disabled]="!administratorForm.valid"
              [loading]="isSubmitting"
              [testId]="'add-quiz-administrator__submit'"
            >Add administrators</app-loading-button>
        </div>
      </form>
    </ng-template>
    <button
      (click)="open(addAdminModal)"
      type="button"
      class="btn text-decoration-underline" data-testid="add-quiz-administrator__modal-trigger">+ Add Administrators</button>
  `
})
export class AddQuizAdministratorComponent {
  @Output() administratorsAdded = new EventEmitter<string[]>();
  @Input() isSubmitting = false;
  private modalService = inject(NgbModal);
  private formBuilder = inject(FormBuilder);

  administratorForm = this.formBuilder.group({
    emailList: ['',[Validators.required, commaSeparatedValidator(Validators.pattern(/^[a-zA-Z0-9._%+-]+@vumc.org$/))]],
  });

  get emailList() {
    return this.administratorForm.get('emailList');
  }

  get emailListErrors() {
    return this.emailList?.errors?.commaSeparatedErrors;
  }

  open(content: TemplateRef<any>) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
	}

  close(modal: NgbActiveModal) {
    this.resetAndClose(modal);
  }

  handleSubmit(modal: NgbActiveModal) {
    this.administratorsAdded.emit(
      this.emailList?.value?.split(',')
        .map((email: string) => email.trim())
    );
    this.resetAndClose(modal);
  }

  resetAndClose(modal: NgbActiveModal) {
    this.administratorForm.reset();
    modal.close();
  }
}
